const suppliers = [
    'ec',
    'xp',
    'pe',
    'ep',
    'mem',
    'other'
];
const superadminSources = [
    {
        value: 'csv',
        display: 'CSV'
    },
    {
        value: 'api',
        display: 'Excard (Api)'
    },
    {
        value: 'apiSg',
        display: 'Excard SG (Api)'
    },
    {
        value: 'apiEast',
        display: 'Excard East (Api)'
    },
    {
        value: 'sticker',
        display: 'SC-sticker'
    },
    {
        value: 'certificate',
        display: 'SC-certificate'
    },
    {
        value: 'document',
        display: 'SC-document'
    },
    {
        value: 'poster',
        display: 'SC-area'
    },
    {
        value: 'loose-sheet-v2',
        display: 'SC-sheet',
    },
    {
        value: 'booklet',
        display: 'SC-booklet'
    },
    {
        value: 'product',
        display: 'SC-item'
    },
];

const sources = [
    {
        value: 'csv',
        display: 'CSV'
    },
    {
        value: 'sticker',
        display: 'SC-sticker'
    },
    {
        value: 'certificate',
        display: 'SC-certificate'
    },
    {
        value: 'document',
        display: 'SC-document'
    },
    {
        value: 'poster',
        display: 'SC-area'
    },
    {
        value: 'loose-sheet-v2',
        display: 'SC-sheet'
    },
    {
        value: 'booklet',
        display: 'SC-booklet'
    },
];

const componentSources = [
    {
        value: 'api',
        display: 'API'
    },
    {
        value: 'csv',
        display: 'CSV'
    },
    {
        value: 'poster',
        display: 'SC-area'
    },
    {
        value: 'loose-sheet-v2',
        display: 'SC-sheet'
    },
    {
        value: 'booklet',
        display: 'SC-booklet'
    },
    {
        value: 'product',
        display: 'SC-item'
    }
];

const role = 'main'; // main, sup

const componentColors = {
    'Material': '#FF595E',
    'Printing Size': '#FF924C',
    'Printing Cost': '#FFCA3A',
    'Quantity': '#8AC926',
    'Production Timeline': '#1982C4',
    'Artwork Service': '#4267AC',
    'Binding Type': '#1479FF',
    remaining: [
        '#9e66ed',
        '#eb66ed',
        '#66dbed',
        '#d2ed66',
        '#8c0023',
        '#8c3300',
        '#c2c2c2',
        '#c3d6a9',
        '#0072c9',
        '#b375ff',
    ],
};
const renderSourceName = (value) => {
    let result = value;
    let findItem = superadminSources.find(x => x.value === value);
    if(findItem){
        result = findItem.display;
    }
    return result;
};

const env = {
    suppliers,
    superadminSources,
    sources: sources,
    componentSources,
    role: role,
    componentColors,
    renderSourceName: renderSourceName
};

export default env;
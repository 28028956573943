import React, { useState, useEffect } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import * as Yup from 'yup';
import Dropdown from '../Common/Dropdown.js';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/common/delete.svg';
import { ReactComponent as DoneStepIcon } from '../../assets/img/icons/common/doneStep.svg';

import ComponentNumberInput from './ComponentNumberInput.js';
import ComponentTextInput from './ComponentTextInput.js';
import DynamicPriceInput from './DynamicPriceInput.js';
import CalculationType from './CalculationType.js';

import { withRouter } from 'react-router';
import env from '../../env.js';

// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import client from '../../feathers.js';
import PosterOptions from './Poster/Options.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
    applicableSource: Yup.string().required('Source is required!'),
});

const Options = (props) => {
    const { isEdit, componentId, dynamicPriceData, componentsTypeId, userId, componentTypeName, onSubmit, duplicate } = props;
    const [supportedSource, setSupportedSource] = useState(env.componentSources);
    const [existingComponent, setExistingComponent] = useState();

    useEffect(() => {
        setSupportedSource(env.componentSources.filter((x) => { return (x.value !== 'csv' && x.value !== 'api'); }));
    }, []);

    useEffect(async () => {
        if (componentId) {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res) => {
                    setExistingComponent({
                        ...res,
                        code: duplicate ? '' : res.code
                    });
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, []);

    const renderPosterOptions = (values, setFieldValue) => {
        if(values?.applicableSource?.includes('poster')){
            return <PosterOptions 
                values={values}
                componentTypeName={componentTypeName}
                setFieldValue={setFieldValue}
                dynamicPriceData={dynamicPriceData}
            />;
        }else{
            return (
                <>
                    <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                        <Col md='0.5' className='p-0 d-flex justify-content-center'>
                            {values.data.options.length > 0 ? (
                                <DoneStepIcon/>
                            ) : (
                                <div className='d-flex align-items-center justify-content-center'
                                    style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                    2
                                </div>
                            )}
                        </Col>
                        <Col md='11'>
                            <Row className='m-0 d-flex align-items-center'>
                                <Col md='2' className='p-0'>
                                    <small className='mr-5 mb-1'>{componentTypeName} Optons* </small>
                                </Col>
                            </Row>
                            <FieldArray
                                className='mb-3'
                                name='data.options'
                                render={({ push, remove, move }) => (
                                    <>
                                        <Col className='mb-3 p-0'>
                                            <DragDropContext onDragEnd={(result) => {
                                                if (!result.destination) {
                                                    return;
                                                }
                        
                                                move(result.source.index, result.destination.index);
                                            }}>

                                                <Droppable droppableId='options'>
                                                    {(provided) => (
                                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                                            {values.data.options.map((option, index) => (
                                                                <Draggable key={index} draggableId={`option-${index}`} index={index}>
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Col md='12' className='p-0'>
                                                                                {values?.applicableSource?.includes('poster') ? (
                                                                                    <Row key={index} className='m-0 mb-1 align-items-center justify-content-start'>
                                                                                        <Col className='pr-0'>
                                                                                            <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                                                <ComponentTextInput
                                                                                                    hasDrag
                                                                                                    placeholder='Label'
                                                                                                    location={`data.options[${index}].label`}
                                                                                                />
                                                                                            </Row>
                                                                                        </Col>

                                                                                        <Col className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'Base Price'}
                                                                                                location={`data.options[${index}].basePrice`}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'Print Cost'}
                                                                                                location={`data.options[${index}].pricePer`}
                                                                                                chipText={values.calcType}
                                                                                            />
                                                                                        </Col>

                                                                                        {values.calcType === 'Sqft' && (
                                                                                            <Col className='p-0'>
                                                                                                <ComponentNumberInput
                                                                                                    type={'number'}
                                                                                                    className='mr-2'
                                                                                                    title={'Wastage'}
                                                                                                    location={`data.options[${index}].wastage`}
                                                                                                    chipText={values.calcType}
                                                                                                />
                                                                                            </Col>
                                                                                        )}
                                                                                                                

                                                                                        <Col md='1'>
                                                                                            <Button 
                                                                                                key={'asd'}
                                                                                                color='danger'
                                                                                                className='btn-round'
                                                                                                onClick={() => remove(index)}
                                                                                            >
                                                                                                <DeleteIcon 
                                                                                                    width={14}
                                                                                                    height={14}
                                                                                                />
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                ) : (
                                                                                    <Row key={index} className='m-0 mb-1 align-items-center justify-content-start'>
                                                                                        <Col md='4' className='pr-0'>
                                                                                            <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                                                <ComponentTextInput
                                                                                                    hasDrag
                                                                                                    placeholder='Label'
                                                                                                    location={`data.options[${index}].label`}
                                                                                                />
                                                                                            </Row>
                                                                                        </Col>

                                                                                        <Col md='3' className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'Base Price'}
                                                                                                location={`data.options[${index}].basePrice`}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col md='3' className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'Print Cost'}
                                                                                                location={`data.options[${index}].pricePer`}
                                                                                                chipText={values.calcType}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col md='1'>
                                                                                            <Button 
                                                                                                key={index}
                                                                                                color='danger'
                                                                                                className='btn-round'
                                                                                                onClick={() => remove(index)}
                                                                                            >
                                                                                                <DeleteIcon 
                                                                                                    width={14}
                                                                                                    height={14}
                                                                                                />
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                            </Col>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </Col>

                                        <Row className='d-flex align-items-start mb-1' style={{ paddingLeft: 15}}>
                                            <a 
                                                href='/' 
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    push({
                                                        label: undefined,
                                                        basePrice: 0,
                                                        pricePer: 0,
                                                        wastage: 0,
                                                    });
                                                }}      
                                            >
                                                <span>
                                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                    Add more options
                                                </span>
                                            </a>
                                        </Row>
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                </>
            );
        }
    };
    return (  
        <>
            <Formik
                initialValues={{
                    _id: (existingComponent && !duplicate) ? existingComponent._id : undefined,
                    userId,
                    applicableSource: (existingComponent) ? existingComponent.applicableSource : undefined,
                    code: (existingComponent) ? existingComponent.code : '',
                    name: (existingComponent) ? existingComponent.name : '',
                    description: (existingComponent) ? existingComponent.description : '',
                    dynamicPriceId: (existingComponent) ? existingComponent.dynamicPriceId : undefined,
                    componentsTypeId,
                    componentTypeName,
                    status: (existingComponent) ? existingComponent.status : 1,
                    calcType: (existingComponent) ? existingComponent.calcType : 'Sheet',
                    type: (existingComponent) ? existingComponent.type : '',
                    defaultSize: (existingComponent) ? existingComponent.defaultSize : 'cm',
                    areaWastage: (existingComponent) ? existingComponent.areaWastage : false,
                    data: (existingComponent) ? existingComponent.data : {
                        options: [{
                            label: undefined,
                            basePrice: 0,
                            pricePer: 0,
                            wastage: 0,
                            minAmount: 0,
                            minPerSide: 0,
                            maxPerSide: 0,
                        }]
                    }
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    } = propsChild;
                    
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body p-0'>
                                <Card className='shadow mb-4'>
                                    <CardBody className='rounded'>
                                        <div>
                                            <div className='px-3 mb-2 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Information</h4>
                                                </Row>
                                                <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Applicable for:</h5>
                                                        <Dropdown
                                                            name={'applicableSource'}
                                                            setFieldValue={setFieldValue}
                                                            disabled={(existingComponent)}
                                                            values={[
                                                                ...supportedSource
                                                            ]}
                                                            width={'100%'}
                                                        />
                                                        {errors.applicableSource && touched.applicableSource ? (
                                                            <small className='text-danger xs'>{errors.applicableSource}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Code</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Code'
                                                            type='text'
                                                            name={'code'}
                                                            maxlength='8'
                                                            normalize={value => (value || '').toUpperCase()}
                                                        />
                                                        {errors.code && touched.code ? (
                                                            <small className='text-danger xs'>{errors.code}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Name</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Name'
                                                            type='text'
                                                            name={'name'}
                                                            maxlength='40'
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <small className='text-danger xs'>{errors.name}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Description</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Description'
                                                            type='text'
                                                            name={'description'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='px-3 mb-4 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Properties</h4>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {values.calcType ? (
                                                            <DoneStepIcon/>
                                                        ) : (
                                                            <div className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                1
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col md='11'>
                                                        <CalculationType 
                                                            width={320}
                                                            background={'white'}
                                                            title={'Calculation Type* '}
                                                            hasSheets={!values?.applicableSource?.includes('poster') && !values?.applicableSource?.includes('product')}
                                                            hasAbsolute={true}
                                                            hasSqft={values?.applicableSource?.includes('poster')}
                                                            hasPerimeter={values?.applicableSource?.includes('poster')}
                                                        />
                                                    </Col>
                                                </Row>

                                                {
                                                    renderPosterOptions(values, setFieldValue)
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <Button
                                                color='secondary'
                                                data-dismiss='modal'
                                                type='button'
                                                onClick={() => {
                                                    props.history.push(`/${props.role}/components`);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color='secondary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 0)}
                                            >
                                                Save as Draft
                                            </Button>
                                            <Button
                                                color='primary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 1)}
                                            >
                                                Save as Active
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(Options);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchMargins, pushMargins, updateMargins, removeMargins } from '../redux/actions/marginsActions';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col
} from 'reactstrap';

import ModalConfirmation from '../components/Extra/ModalConfirmation.js';

import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import { ReactComponent as DeleteIcon } from '../assets/img/icons/common/trash.svg';
import { ReactComponent as EditIcon } from '../assets/img/icons/common/edit.svg';

import client from '../feathers';
import axios from 'axios';

const PAGESIZE = 20;

function paginationCount(length, currentPage) {
    return {
        total: length,
        per_page: PAGESIZE,
        current_page: currentPage,
        last_page: Math.ceil(length / PAGESIZE),
        from: ((currentPage - 1) * PAGESIZE) + 1,
        to: currentPage * PAGESIZE
    };
}

const Margins = (props) => {
    const [ modalVisible, setMmodalVisible ] = useState(false);
    const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false);
    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ dataEdit, setDataEdit ] = useState({});
    const [ pagination, setPagination ] = useState({});
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ totalMargins, setTotalMargins ] = useState(0);
    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,
    
        actions: modalActions,
    } = useModal();

    useEffect(() => {
        fetchData(props.userId, props.fetchMargins, notificationOpen, 0, 1);
    }, [props.userId, props.fetchMargins]);

    const toggleModal = () => {

        if(props.role !== 'superadmin'){
            // If not superadmin
            // Check numbOfMargins
            let numbOfMargins = props.userInfo.numbOfMargins?props.userInfo.numbOfMargins:3;
            if(props.marginsData.length < numbOfMargins){
                setMmodalVisible(!modalVisible);
            }else{
                notificationOpen(true, 'danger', 'Exceed Number of Algorithm!');
            }
        }else{
            setMmodalVisible(!modalVisible);
        }
    };

    const toggleModalEdit = () => {
        setMmodalVisibleEdit(!modalVisibleEdit);
    };

    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm);
    };

    const modalCallback = (e) => {
        client.authenticate()
            .then((auth)=>{
                return axios.post(`${client.io.io.uri}deleteMarginAndPatchProductsMargin`, {
                    role: props.role,
                    marginId: dataEdit._id
                },
                {
                    headers: {
                        'Authorization': auth.accessToken
                    }
                });
            })
            .then((res) => {
                // console.log(res)
                props.removeMargins({
                    _id: dataEdit._id
                });
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };
  
    const fetchData = (userId, fetchMargins, notificationOpen, skip, currentPage) => {
        if(userId){
            client.authenticate()
                .then(()=>{
                    return client.service('margins').find({
                        query: {
                            userId: userId,
                            $skip: skip,
                            $limit: PAGESIZE,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    setPagination(paginationCount(res.total, currentPage));
                    setTotalMargins(res.total);
                    fetchMargins(res.data);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

  
    const PaginationRender = () => {
        if(pagination.last_page > 0){
            let pageArr = [];
            for(let i = 1; i <= pagination.last_page; i++){
                pageArr.push(i);
            }
            return (
                <Pagination
                    className='pagination justify-content-center mb-0'
                    listClassName='justify-content-center mb-0'
                >
                    <PaginationItem className={(pagination.current_page > 1?'':'disabled')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                                fetchData(props.userId, props.fetchMargins, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1);
                            }} 
                            tabIndex='-1'
                        >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {pageArr.map((i) => {
                        return (
                            <PaginationItem key={i} className={(pagination.current_page === i? 'active': '')}>
                                <PaginationLink
                                    onClick={() => {
                                        setPagination(paginationCount(pagination.total, i)); 
                                        fetchData(props.userId, props.fetchMargins, notificationOpen, (i-1)*PAGESIZE, i);
                                    }} 
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem className={((pagination.current_page+1) > pagination.last_page?'disabled':'')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                                fetchData(props.userId, props.fetchMargins, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1);
                            }} 
                        >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            );
        }else{
            return null;
        }
    };

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            {/* Page content */}
            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='mr-4 mb-0'>Margin Settings {(props.packageDetails && props.role !== 'superadmin') ? `(${totalMargins}/${props.packageDetails.productRestrictions.total})` : ''}</h2>
                </Row>
                <Row className='mt-3'>
                    <div className='col'>
                        <Card className='shadow px-2 rounded'>
                            <CardBody className='py-0'>
                                <CardHeader className='bg-transparent border-0'>
                                    <Row className='d-flex align-items-center justify-content-between'>
                                        <Media>
                                            <Media body>
                                                <h3 className='mb-0'>Profit Margin Table</h3>
                                            </Media>
                                        </Media>
                                        <Button
                                            color='primary'
                                            onClick={() => props.history.push(`/${props.role}/margins-create`)}
                                        >
                                            <span className='btn-inner--text'>+ Profit Margin</span>
                                        </Button>
                                    </Row>
                                </CardHeader>
                                <div className='table-responsive mac-scrollbar rounded border'>
                                    <Table className='align-items-center table-flush table rounded' style={{minHeight: 200}}>
                                        <thead style={{ height: 50 }}>
                                            <tr>
                                                <th scope='col'>Name</th>
                                                <th scope='col'>Created Date</th>
                                                <th scope='col'>Description</th>
                                                <th scope='col'>Deployment</th>
                                                <th scope='col' className='text-right'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.marginsData.length > 0? (
                                                props.marginsData.map((v, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                {v.marginName}
                                                            </td>
                                                            <td>{moment(v.createdAt).format('DD-MM-YYYY')}</td>
                                                            <td>{v.description ?? '-'}</td>
                                                            <td>{v.deploymentCount}</td>
                                                            <td className='text-right'>
                                                                <Button
                                                                    color='grey'
                                                                    className='btn-square'
                                                                    onClick={() => {
                                                                        props.history.push(`/${props.role}/margins-edit/${v._id}`);
                                                                        setDataEdit(v);
                                                                    }}
                                                                >
                                                                    <EditIcon height={15} width={15}/>
                                                                </Button>
                                                                <Button
                                                                    color='grey'
                                                                    className='btn-square'
                                                                    onClick={() => {
                                                                        setDataEdit(v);
                                                                        toggleModalConfirm();
                                                                    }}
                                                                >
                                                                    <DeleteIcon height={15} width={15}/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ): (
                                                <tr>
                                                    <td>
                                                        <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <CardFooter className='py-4'>
                                    <nav aria-label='...'>
                                        <PaginationRender />
                                    </nav>
                                </CardFooter>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
     
            <ModalConfirmation 
                modalVisible={modalConfirm} 
                toggleModal={toggleModalConfirm} 
                modalCallback={modalCallback} 
                title='Delete Margin'
                content={(
                    <div className='text-center'>
                        <div className='text-danger mb-1'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='currentColor' className='bi bi-exclamation-triangle-fill' viewBox='0 0 16 16'>
                                <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z'/>
                            </svg>
                        </div>
                        <h3>Are you sure want delete this Margin?</h3>
                        <hr style={{ marginTop: '1rem', marginBottom: '1rem', width: '80%'}}/>
                        <p className='font-weight-400 text-muted'><strong>Product's Margin</strong> will be removed also those linked to this <strong>Margin</strong>!</p>
                    </div>
                )}
            />
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    marginsData: state.margins.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
    fetchMargins: fetchMargins,
    pushMargins: pushMargins,
    updateMargins: updateMargins,
    removeMargins: removeMargins,
};
export default connect(mapStateToProps, mapDispatchToProps)(Margins);
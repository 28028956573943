export const getSizes = (component) => {
    const sizes = component.data.printingSizeDetails.sizes;
    if (component.data.printingSizeDetails.hasCustomSize) {
        sizes.push({
            label: 'Custom'
        });
    }
    return sizes;
};

export const getQuantities = (isCustom, values) => {
    const data = [];

    if (isCustom) {
        for (let i = values.data.quantity.start; i <= values.data.quantity.end; i += values.data.quantity.inMultipleOf) {
            data.push(i);
        }
        const custom = values.data.quantity.custom.map((x) => x.quantity);
        data.push(...custom);
    } else {
        for (let i = values.data.quantity.min; i <= values.data.quantity.max; i += 1) {
            data.push(i);
        }
    }
    const sorted = [...new Set(data)].filter((x) => (x)).sort((a, b) => a - b);
    return sorted.map((x) => {
        return {
            value: x,
            display: x,
        };
    });
};

export default {
    getSizes,
    getQuantities,
};
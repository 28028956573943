import roleReducer from './roleReducer';
import productsReducer from './productsReducer';
import usersReducer from './usersReducer';
import companyReducer from './companyReducer';
import invoicesReducer from './invoicesReducer';
import transactionsReducer from './transactionsReducer';
import statusReducer from './statusReducer';
import marginsReducer from './marginsReducer';
import groupsReducer from './groupsReducer';
import dynamicPriceReducer from './dynamicPriceReducer';
import licensesReducer from './licensesReducer';
import components from './componentsReducer';
import componentsType from './componentsTypeReducer';
import packageReducer from './packageReducer';
import allPackageReducer from './allPackageReducer';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    role: roleReducer,
    products: productsReducer,
    users: usersReducer,
    company: companyReducer,
    invoices: invoicesReducer,
    transactions: transactionsReducer,
    status: statusReducer,
    margins: marginsReducer,
    groups: groupsReducer,
    dynamicPrice: dynamicPriceReducer,
    licenses: licensesReducer,
    components: components,
    componentsType: componentsType,
    packageDetails: packageReducer,
    allPackages: allPackageReducer
});

export default rootReducer;
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/common/plus.svg';
import { withRouter } from 'react-router';
import { useComponents } from '../../../../zustand/componentStore.js';
import Dropdown from '../../../../components/Common/Dropdown.js';
import apiType from '../../../Products/apiProductType.js';

import {
    Row,
    Button,
    Col,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';
import DraggableComponent from '../DraggableComponent.js';
import ComponentSelector from '../ComponentSelector.js';
import PricingRuleSelector from '../PricingRuleSelector.js';
import MarginSection from '../MarginSection.js';
import CustomFieldSection from '../CustomFieldSection.js';
import DynamicPriceSection from '../DynamicPriceSection.js';

import client from '../../../../feathers.js';

const APIComponentStructure = (props) => {
    const {
        userId,
        values,
        setFieldValue,
        existingProduct,
        marginsData,
        dynamicPriceData,
        role,
        notificationOpen,
        productGroups,

        componentsTypeData,
    } = props;

    const { actions } = useComponents();
    const uploadFileRef = useRef();
    const [products, setProducts] = useState([]);
    // const [ availability, setAvailability ] = useState('In Stock')

    const getComponentTypeId = (name) => {
        return componentsTypeData.find((x) => x.name === name)?._id;
    };

    useEffect(() => {
        if(userId && values.apiSource && (values.apiSource !== 'api') && (values.apiSource !== 'apiSg') && (values.apiSource !== 'apiEast')) {
            client.authenticate()
                .then(()=>{
                    return client.service('product-groups').get(values.apiSource);
                })
                .then((res)=>{
                    return client.service('products').find({
                        query: {
                            _id: { $in: res.productIds.map((x) => x._id)},
                            $limit: 1000
                        }
                    });
                })
                .then((res)=>{
                    if(res.data?.length > 0){
                        res.data = res.data.sort((a, b) => {
                            if (a.name < b.name) {
                                return -1;
                            }
                            if (a.name > b.name) {
                                return 1;
                            }
                            return 0;
                        });
                        
                        setProducts(res.data);
                    }
                });
        }
    }, [values.apiSource]);

    useEffect(() => {
        if(userId && values.groupProduct) {
            client.authenticate()
                .then(()=>{
                    return client.service('products').get(values.groupProduct);
                })
                .then((res)=>{
                    setFieldValue('innerProduct', res);
                });
        }
    }, [values.groupProduct]);

    return (
        <>
            <div className='rounded bg-light-grey px-3 py-2 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <Col md='4'>
                        <small>API*</small>
                        <Dropdown
                            name={'apiSource'}
                            setFieldValue={setFieldValue}
                            values={productGroups.map((x) => {
                                return {
                                    value: x.value,
                                    display: x.display,
                                };
                            })}
                            width={'100%'}
                        />
                    </Col>
                    
                    <Col md='8'>
                        <small>API Product*</small>
                        {(values.apiSource === 'api') || (values.apiSource === 'apiSg') || (values.apiSource === 'apiEast') ? (
                            <Dropdown
                                name={'apiType'}
                                setFieldValue={setFieldValue}
                                values={apiType.sort().map((x) => {
                                    return {
                                        value: x,
                                        display: x.toUpperCase().replace('||', ' - '),
                                    };
                                })}
                                width={'100%'}
                            />
                        ) : (
                            <Dropdown
                                name={'groupProduct'}
                                setFieldValue={setFieldValue}
                                values={products.map((x) => {
                                    return {
                                        value: x._id,
                                        display: x.name,
                                    };
                                })}
                                width={'100%'}
                            />
                        )}
                       
                    </Col>
                </Row>

            </div>

            {(role !== 'superadmin') && (
                <>
                    <div className='rounded bg-grey py-2 px-3 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>Production Timeline</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.productionComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('productionComponents'),
                                    actions.setSelectedComponentType('Production Timeline'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('Production Timeline')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='productionComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.productionComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.productionComponents}
                                            supportNested={false}
                                            location={'productionComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div className='rounded bg-grey py-2 px-3 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>Artwork Service</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.artworkComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('artworkComponents'),
                                    actions.setSelectedComponentType('Artwork Service'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('Artwork Service')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='artworkComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.artworkComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.artworkComponents}
                                            supportNested={false}
                                            location={'artworkComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div className='rounded bg-grey px-3 py-2 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>File Upload</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.fileStorageComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('fileStorageComponents'),
                                    actions.setSelectedComponentType('File Storage'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('File Storage')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='fileStorageComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.fileStorageComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.fileStorageComponents}
                                            supportNested={false}
                                            location={'fileStorageComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <CustomFieldSection
                        values={values}
                        setFieldValue={setFieldValue}
                        onAdd={() => [
                            actions.setCustomFieldModalIsOpen(true),
                        ]}
                    />
                </>
            )}

            <MarginSection
                values={values}
                marginsData={marginsData}
                role={role}
                setFieldValue={setFieldValue}
                onAddMargin={() => [
                    actions.setModalAllowBack(true),
                    actions.setSelectedPricingRule('Margin'),
                    actions.setPricingRuleModalIsOpen(true),
                ]}
            />

            {(!values?.apiSource?.toLowerCase()?.includes('api')) && (!values?.innerProduct?.apiSource?.includes('api')) && (
                <DynamicPriceSection
                    values={values}
                    dynamicPriceData={dynamicPriceData}
                    role={role}
                    setFieldValue={setFieldValue}
                    onAddDyamicPrice={() => [
                        actions.setModalAllowBack(true),
                        actions.setSelectedPricingRule('Dynamic Price'),
                        actions.setPricingRuleModalIsOpen(true),
                    ]}
                />
            )}
            
            
            <ComponentSelector
                userId={userId}
                values={values}
                applicableSource={values.source}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />

            <PricingRuleSelector
                userId={userId}
                values={values}
                applicableSource={values.source}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />
        </>
    );
};

const mapStateToProps = state => ({
    componentsTypeData: state.componentsType.data,
    role: state.role.details.user?state.role.details.user.role:''
});
  
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(APIComponentStructure));

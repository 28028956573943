import React, { useState, useEffect, useRef } from 'react';
import papa from 'papaparse';
import axios from 'axios';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/common/plus.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/img/icons/common/upload.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/icons/common/trash.svg';
import { withRouter } from 'react-router';
import { useComponents } from '../../../../zustand/componentStore.js';

import {
    Row,
    Button,
    Col,
    FormGroup,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';
import DraggableComponent from '../DraggableComponent.js';
import ComponentSelector from '../ComponentSelector.js';
import PricingRuleSelector from '../PricingRuleSelector.js';
import MarginSection from '../MarginSection.js';
import CustomFieldSection from '../CustomFieldSection.js';
import DynamicPriceSection from '../DynamicPriceSection.js';

import client from '../../../../feathers.js';

const CSVComponentStructure = (props) => {
    const {
        userId,
        values,
        setFieldValue,
        existingProduct,
        marginsData,
        dynamicPriceData,
        role,
        notificationOpen,

        componentsTypeData,
    } = props;

    const { actions } = useComponents();
    const uploadFileRef = useRef();
    // const [ availability, setAvailability ] = useState('In Stock')
    const [ invalidCsvQuantity, setInvalidCsvQuantity ] = useState(false);
    const [ readingFile, setReadingFile ] = useState(false);
    const [ newCsvFile, setNewCsvFile ] = useState();
    const [ progress, setProgress ] = useState(0);

    const getComponentTypeId = (name) => {
        return componentsTypeData.find((x) => x.name === name)?._id;
    };

    const handleFileChange = async(e) => {
        setProgress(0);
        
        let singleFile = e.target.files[0];
        
        let fileType = (singleFile.type === 'application/vnd.ms-excel' || singleFile.type === 'text/csv');
    
        if(fileType){    
            setNewCsvFile(singleFile);
    
            return e.target.value = null;
        }else{
            // eslint-disable-next-line no-undef
            window.alert('Only Accept CSV File!');
            return e.target.value = null;
        }
    };

    const csvUpload = async (file) => {
        if(newCsvFile) {
            let newFileName = (Math.random().toString(36).substring(2, 15) + '-' + 
            file.lastModified + '-' + 
            file.name);
            const formData = new FormData();
            formData.append('NAME', 'Fred');
            formData.append('file', file, newFileName);
                
            await axios({
                method: 'post',
                url: `${client.io.io.uri}uploadCSVLocal`,
                data: formData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
                .then((res) => {
                    setFieldValue('csvFile.fileUrl', res.data);
                    setFieldValue('csvFile.fileName', file.name);
                    setFieldValue('preview.values', []);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        if (newCsvFile) {
            setReadingFile(true);
            setInvalidCsvQuantity(false);
            setFieldValue('quantity', 1);
            setFieldValue('csvFile.isSingleQuantity', true);

            try {
                // eslint-disable-next-line no-undef
                const reader = new FileReader();
                reader.onload = async ({ target }) => {
                    const csv = papa.parse(target.result);

                    const parsedCsvData = csv?.data;

                    const quantityIndex = parsedCsvData[0].findIndex((e) => e === 'Quantity');
                    if(quantityIndex < 0) {
                        notificationOpen(true, 'danger', 'No quantity column found!');
                    }

                    for (let index = 0; index < parsedCsvData.length; index++) {
                        if(index > 0 && parsedCsvData[index][quantityIndex] && parsedCsvData[index][quantityIndex] !== '1') {
                            setInvalidCsvQuantity(true);
                            setFieldValue('csvFile.isSingleQuantity', false);
                            break;
                        }
                    }
                    setReadingFile(false);
                };
                reader.readAsText(newCsvFile);
            } catch (err) {
                console.log(err);
                return false;
            }

            csvUpload(newCsvFile);
            setFieldValue('preview.values', []);
            setFieldValue('quantityComponents', []);
        }
    }, [newCsvFile]);


    const onAddQuantity = async () => {
        if(!invalidCsvQuantity && values?.csvFile?.isSingleQuantity) {
            actions.setStoredLocation('quantityComponents');
            actions.setSelectedComponentType('Quantity');
            actions.setSelectedComponentTypeId(getComponentTypeId('Quantity'));
            actions.setModalAllowBack(false);
            actions.setAddComponentModalIsOpen(true);
        } else {
            notificationOpen(true, 'danger', 'Quantities in uploaded csv needs to be 1!');
        }
    };

    return (
        <>
            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Upload CSV Pricing File*</h4>
                </Row>

                <Row className='px-3 py-2'>
                    <Col md='12'>
                        <FormGroup className='text-center mb-0'>
                            <div className='file-upload'>
                                <input 
                                    type='file' 
                                    ref={uploadFileRef}
                                    accept='.csv' 
                                    onChange={(e) => handleFileChange(e)} 
                                    hidden />                
                                <div className='progessBar' style={{ width: progress }}>
                                </div>
                                <Button
                                    className='m-0 w-100 py-3 shadow-none'
                                    disabled={values.csvFile.fileUrl}
                                    onClick={() => uploadFileRef.current.click()}
                                >
                                    <div className='btn-inner--icon d-flex align-items-center justify-content-center'>
                                        <span className='btn-inner--text' style={{ opacity: 0.5 }}>Select File</span>
                                        <UploadIcon />
                                    </div>
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='px-3 py-2 d-flex justify-content-end align-items-center'>
                    {(values.csvFile.fileName) && (
                        <>
                            <small className='mr-2'>{values.csvFile.fileName}</small>
                            <DeleteIcon 
                                style={{ cursor: 'pointer' }}
                                onClick={() => [
                                    setFieldValue('csvFile', {}),
                                    setNewCsvFile()
                                ]}
                            />
                        </>
                    )}
                </Row>
            </div>

            <div className='rounded bg-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Quantity</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        style={{ height: 25, width: 25 }}
                        disabled={values.quantityComponents.length > 0 || readingFile}
                        onClick={() => onAddQuantity()}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                
                <Droppable droppableId='quantityComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                            {values?.quantityComponents?.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.quantityComponents}
                                    supportNested={false}
                                    location={'quantityComponents'}
                                    description={c.description}
                                />                                                                                                  
                            ))}
                                
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>


            {(role !== 'superadmin') && (
                <>
                    <div className='rounded bg-grey py-2 px-3 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>Production Timeline</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.productionComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('productionComponents'),
                                    actions.setSelectedComponentType('Production Timeline'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('Production Timeline')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='productionComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.productionComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.productionComponents}
                                            supportNested={false}
                                            location={'productionComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div className='rounded bg-grey py-2 px-3 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>Artwork Service</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.artworkComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('artworkComponents'),
                                    actions.setSelectedComponentType('Artwork Service'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('Artwork Service')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='artworkComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.artworkComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.artworkComponents}
                                            supportNested={false}
                                            location={'artworkComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div className='rounded bg-grey px-3 py-2 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>File Upload</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.fileStorageComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('fileStorageComponents'),
                                    actions.setSelectedComponentType('File Storage'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('File Storage')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='fileStorageComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.fileStorageComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.fileStorageComponents}
                                            supportNested={false}
                                            location={'fileStorageComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <CustomFieldSection
                        values={values}
                        setFieldValue={setFieldValue}
                        onAdd={() => [
                            actions.setCustomFieldModalIsOpen(true),
                        ]}
                    />
                </>
            )}

            <MarginSection
                values={values}
                marginsData={marginsData}
                role={role}
                setFieldValue={setFieldValue}
                onAddMargin={() => [
                    actions.setModalAllowBack(true),
                    actions.setSelectedPricingRule('Margin'),
                    actions.setPricingRuleModalIsOpen(true),
                ]}
            />

            <DynamicPriceSection
                values={values}
                dynamicPriceData={dynamicPriceData}
                role={role}
                setFieldValue={setFieldValue}
                onAddDyamicPrice={() => [
                    actions.setModalAllowBack(true),
                    actions.setSelectedPricingRule('Dynamic Price'),
                    actions.setPricingRuleModalIsOpen(true),
                ]}
            />
            
            <ComponentSelector
                userId={userId}
                values={values}
                applicableSource={values.source}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />

            <PricingRuleSelector
                userId={userId}
                values={values}
                applicableSource={values.source}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />
        </>
    );
};

const mapStateToProps = state => ({
    componentsTypeData: state.componentsType.data,
    role: state.role.details.user?state.role.details.user.role:'',
});
  
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CSVComponentStructure));

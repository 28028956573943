import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/common/delete.svg';

import DefaultSize from '../DefaultSize.js';
import { ReactComponent as DoneStepIcon } from '../../../assets/img/icons/common/doneStep.svg';
import ComponentNumberInput from '../ComponentNumberInput.js';
import DynamicPriceInput from '../DynamicPriceInput.js';
// reactstrap components
import {
    Row,
    Col,
    Button,
} from 'reactstrap';

const MaterialPosterSets = ({
    componentTypeName,
    values,
    data,
    index,
    setFieldValue,
    dynamicPriceData,
    remove,
}) => {
    return (  
        <>
            <div className='px-3 mb-1 border rounded'>
                <Row className='p-3 px-4 d-flex align-items-center justify-content-between'>
                    <h4 className='m-0'>{componentTypeName} Properties ({index+1})</h4>
                    <Button
                        key={`delete-${index}`}
                        color='danger'
                        className='btn-round'
                        onClick={() => remove(index)}
                    >
                        <DeleteIcon 
                            width={14}
                            height={14}
                        />
                    </Button>
                </Row>
                
                {
                    index === 0 &&
                    <>
                        <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                            {values.defaultSize ? (
                                <DoneStepIcon className='mr-4'/>
                            ) : (
                                <div className='mr-4 d-flex align-items-center justify-content-center'
                                    style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                    1
                                </div>
                            )}
                            <DefaultSize
                                width={270}
                                background={'white'}
                            />
                            <Col md='4' className=''>
                                <small className='mb-2'>Lower cost of</small>
                                <div className='d-flex align-items-center'>
                                    <small className='mx-2'>Area</small>
                                    <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                        <input 
                                            type='checkbox' 
                                            checked={values.areaWastage}
                                            onClick={() => setFieldValue('areaWastage', !values.areaWastage)}
                                        />
                                        <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                                    </label>
                                    <small className='mx-2'>Wastage</small>
                                </div>
                            </Col>
                        </Row>
                    </>
                }

                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                    {(data.printingArea.maxWidth && data.printingArea.maxHeight) ? (
                        <DoneStepIcon className='mr-4'/>
                    ) : (
                        <div className='mr-4 d-flex align-items-center justify-content-center'
                            style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                            2
                        </div>
                    )}
                    <Col className='p-0 m-0'>
                        <Row className='m-0 d-flex align-items-center'>
                            <small className='mr-1 mb-1'>Material Print Area* </small>
                        </Row>
                        <Row className='m-0 d-flex align-items-center'>
                            <Col md='3' className='p-0'>
                                <ComponentNumberInput
                                    type={'number'}
                                    className='mr-2'
                                    title={'Max Width:'}
                                    location={`dataSets.${index}.printingArea.maxWidth`}
                                    chipText={values.defaultSize}
                                />
                            </Col>
                            <Col md='3' className='p-0'>
                                <ComponentNumberInput
                                    type={'number'}
                                    className='mr-2'
                                    title={'Max Height:'}
                                    location={`dataSets.${index}.printingArea.maxHeight`}
                                    chipText={values.defaultSize}
                                />
                            </Col>
                                                        
                        </Row>
                    </Col>
                </Row>
                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                    {(data.costing.basePrice || data.costing.perSheetPrice || data.costing.minAmount) ? (
                        <DoneStepIcon className='mr-4'/>
                    ) : (
                        <div className='mr-4 d-flex align-items-center justify-content-center'
                            style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                            3
                        </div>
                    )}
                    <Col className='p-0 m-0'>
                        <Row className='m-0 d-flex align-items-center'>
                            <small className='mr-1 mb-1'>Costing* </small>
                        </Row>
                        <Row className='m-0 d-flex align-items-center'>
                            <Col md='3' className='p-0'>
                                <ComponentNumberInput
                                    type={'number'}
                                    className='mr-2'
                                    title={'Base Price'}
                                    location={`dataSets.${index}.costing.basePrice`}
                                />
                            </Col>
                            {values.applicableSource && values.applicableSource.includes('poster') ? (
                                <>
                                    <Col md='3' className='p-0'>
                                        <ComponentNumberInput
                                            type={'number'}
                                            className='mr-2'
                                            title={'Min Amount'}
                                            location={`dataSets.${index}.costing.minAmount`}
                                        />
                                    </Col>
                                    <Col md='3' className='p-0'>
                                        <ComponentNumberInput
                                            type={'number'}
                                            className='mr-2'
                                            title={'Price Per'}
                                            location={`dataSets.${index}.costing.perSqftPrice`}
                                            chipText={'sqft'}
                                        />
                                    </Col>
                                    <Col md='3' className='p-0'>
                                        <ComponentNumberInput
                                            type={'number'}
                                            title={'Wastage'}
                                            location={`dataSets.${index}.costing.wastage`}
                                        />
                                    </Col>
                                </>
                            ) : (
                                <Col md='3' className='p-0'>
                                    <ComponentNumberInput
                                        type={'number'}
                                        title={'Per Sheet'}
                                        location={`dataSets.${index}.costing.perSheetPrice`}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>

                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                    {(data.dynamicPriceId) ? (
                        <DoneStepIcon className='mr-4'/>
                    ) : (
                        <div className='mr-4 d-flex align-items-center justify-content-center'
                            style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                            4
                        </div>
                    )}
                    <div>
                        <Row className='m-0 d-flex align-items-center'>
                            <small className='mr-1 mb-1'>Dynamic Price (optional) </small>
                        </Row>
                        <DynamicPriceInput
                            setFieldValue={setFieldValue}
                            dynamicPriceData={dynamicPriceData.filter((x) => x.type === 'length')}
                            location={`dataSets.${index}.dynamicPriceId`}
                        />
                    </div>
                </Row>
            </div>
        </>
    );
};
 
export default MaterialPosterSets;
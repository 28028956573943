import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/common/close.svg';

import {
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

const NonDraggableCard = (props) => {
    const {
        title,
        name,
        description,
        storedLocation,
        setFieldValue,
    } = props;
    const [isHover, setIsHover] = useState(false);

    return (
        <Row
            className='d-flex mx-0 p-1 my-1 rounded align-items-center justify-content-between bg-white border-primary'
            style={{ height: 45 }}
        >
            <Col md='2' className='pr-0'>
                <small className='component-code m-0 text-uppercase'>{title}</small>
            </Col>
            <Col md='9' className='pr-0'>
                {(isHover) && (
                    <Card className='bg-yellow shadow rounded position-absolute' style={{ width: 300, minHeight: 100, zIndex: 10, left: '-18%', top: -110}}>
                        <CardBody className='p-3'>
                            <Row className='p-0 m-0 mb-2'>
                                <small>{title} | {name}</small>
                            </Row>
                            <Row className='p-0 m-0'>
                                <h5 className='m-0'>Description:</h5>
                            </Row>
                            <Row className='p-0 m-0'>
                                <small>{(description) ? description : '-'}</small>
                            </Row>
                        </CardBody>
                        <div className='position-absolute'
                            style={{
                                left: '45%',
                                bottom: '-10px',
                                width:0,
                                borderTop:'10px solid #FFCA3A',
                                borderLeft:'7px solid transparent',
                                borderRight:'7px solid transparent',
                            }} />
                    </Card>
                )}
                <small onMouseOver={() => setIsHover(true)} onMouseOut={() =>setIsHover(false)} >{name}</small>
            </Col>
            <Col md='1' className='p-0'>
                <a
                    className='nav-item'
                    style={{color: 'red'}}
                    onClick={() => [
                        setFieldValue(storedLocation , undefined)
                    ]}
                >
                    <p className='font-weight-bold m-0 text-center'>
                        <CloseIcon 
                            width={22}
                            height={22}
                            stroke={'red'}
                        />
                    </p>
                </a>
            </Col>
        </Row>
    );
};

export default withRouter(NonDraggableCard);

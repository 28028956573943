import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { fetchMargins } from '../redux/actions/marginsActions';
import { fetchDynamicPrice } from '../redux/actions/dynamicPriceActions';
import { fetchProducts, pushProducts, updateProducts, moveProducts, removeProducts } from '../redux/actions/productsActions';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    // Badge,
    Card,
    CardHeader,
    CardFooter,
    Input,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap';

import ProductDescModalEdit from '../components/Products/ProductDescModalEdit.js';

import AssignFirstMargin from '../components/UserProducts/AssignFirstMargin.js';
import MarginsModalEdit from '../components/Extra/MarginsModalEdit.js';

import ModalConfirmation from '../components/Extra/ModalConfirmation.js';
import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import client from '../feathers';
import env from '../env.js';

const PAGESIZE = 20;

var typingTimer = 0;

function paginationCount(length, currentPage) {
    return {
        total: length,
        per_page: PAGESIZE,
        current_page: currentPage,
        last_page: Math.ceil(length / PAGESIZE),
        from: ((currentPage - 1) * PAGESIZE) + 1,
        to: currentPage * PAGESIZE
    };
}

function getConfirmTitle(modalEvent){
    if(modalEvent === 'duplicate'){
        return 'Product Duplicate';
    }else if(modalEvent === 'updateAll'){
        return 'Update All Product';
    }else if(modalEvent === 'delete'){
        return 'Delete Product';
    }else{
        return 'Product Status';
    }
}

function getConfirmContent(modalEvent, status){
    if(modalEvent === 'duplicate'){
        return 'Are you sure want to duplicate this?';
    }else if(modalEvent === 'updateAll'){
        return 'Update All Merchant Product Info(Except Margin)';
    }else if(modalEvent === 'delete'){
        return 'Are you sure want to Delete this Product?';
    }else{
        if(status){
            return 'Are you sure want to Disable this?';
        }else{
            return 'Are you sure want to Enable this?';
        }
    }
}

function sourceName(source){
    if(source === 'csv'){
        return 'CSV';
    }else if(source === 'api'){
        return 'Excard (Api)';
    }else if(source === 'apiSg'){
        return 'Excard SG (Api)';
    }else if(source === 'apiEast'){
        return 'Excard East (Api)';
    }else if(source === 'sticker'){
        return 'SC-Sticker';
    }else if(source === 'certificate'){
        return 'SC-Certificate';
    }else if(source === 'document'){
        return 'SC-Document';
    }else if(source === 'poster'){
        return 'SC-Area';
    }else if(source === 'loose-sheet'){
        return 'SC-Sheet';
    }else if(source === 'csv-prefix'){
        return 'CSV-assigned';
    }else if(source === 'sticker-prefix'){
        return 'SC-Sticker-assigned';
    }else if(source === 'certificate-prefix'){
        return 'SC-Certificate-assigned';
    }else if(source === 'document-prefix'){
        return 'SC-Document-assigned';
    }else if(source === 'poster-prefix'){
        return 'SC-Area-assigned';
    }else if(source === 'loose-sheet-prefix'){
        return 'SC-Sheet-assigned';
    }else if(source === 'api-prefix'){
        return 'API-EC-assigned';
    }else if(source === 'product'){
        return 'SC-Item';
    }else{
        return source;
    }
}

const UserProducts = (props) => {
    const [ modalVisible, setMmodalVisible ] = useState(false);
    const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false);
    const [ modalVisibleMarginEdit, setModalVisibleMarginEdit ] = useState(false);
    const [ modalVisibleDetailEdit, setModalVisibleDetailEdit ] = useState(false);
    const [ modalVisibleAssignMargin, setModalVisibleAssignMargin ] = useState(false);
    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ modalEvent, setModalEvent ] = useState('disable');
    const [ dataEdit, setDataEdit ] = useState({});
    const [ statusOnChange, setStatusOnChange ] = useState({id:'', status:true});
    const [ pagination, setPagination ] = useState({});
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ user, setUser ] = useState({});
    const [ user_Id, setUser_Id ] = useState('');
    const [ checkboxChecked, setCheckboxChecked ] = useState([]);
    const [ regexProductName, setRegexProductName ] = useState('');
    const [ supplier, setSupplier ] = useState('');
    const [ source, setSource ] = useState('');
    const [ tableSorting, setTableSorting ] = useState({
        name: 1,
        source: 1,
        supplier: 1,
        updatedAt: 1
    });
    const [ productGroups, setProductGroups ] = useState([]);
    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,

        actions: modalActions,
    } = useModal();

    useEffect(() => {
        let id = (props.location.pathname.split('/superadmin/userProducts/').join(''));
        setUser(props.usersData.find(e => e._id === id));
        setUser_Id(id);
    }, [props.location.pathname, props.usersData]);

    useEffect(() => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
      
            // remember check all parameter correct
            fetchData(user_Id, props.fetchProducts, notificationOpen, 0, 1, props.fetchMargins, props.fetchDynamicPrice, props.userId, regexProductName, supplier, source);

        }, 500);
    }, [user_Id, props.userId, props.fetchProducts, props.fetchMargins, props.fetchDynamicPrice, regexProductName, supplier, source, tableSorting]);

    useEffect(() => {
    
        client.authenticate()
            .then((auth)=>{
                return client.service('product-groups').find({
                    query: {
                        userIds: {
                            $in: user_Id
                        }
                    }
                });
            })
            .then((res)=>{
                setProductGroups(res.data);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    }, [user_Id]);

    const toggleModal = () => {
        setMmodalVisible(!modalVisible);
    };

    const toggleModalEdit = () => {
        setMmodalVisibleEdit(!modalVisibleEdit);
    };

    const toggleModalMarginEdit = () => {
        setModalVisibleMarginEdit(!modalVisibleMarginEdit);
    };

    const toggleModalDetailEdit = () => {
        setModalVisibleDetailEdit(!modalVisibleDetailEdit);
    };

    const toggleModalAssignMargin = () => {
        setModalVisibleAssignMargin(!modalVisibleAssignMargin);
    };

    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm);
    };

    const modalCallback = (e) => {
        if(modalEvent === 'duplicate'){
            duplicateProduct();
        }else if(modalEvent === 'delete'){
            deleteProduct();
        }else{
            productStatus(statusOnChange.id, statusOnChange.status);
        }
    };

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
    // setIsOpen(bool)
    // setColor(color)
    // setMessage(message)
    };
  
    const sortList = (item, value) => {
        setTableSorting(prevState => {
            delete prevState[item];
            return {
                [item]: value,
                ...prevState,
            };
        });
    };

    const fetchData = (userId, fetchProducts, notificationOpen, skip, currentPage, fetchMargins, fetchDynamicPrice, adminUserId, regexProductName, filterSupplier, source) => {
        let searchParams = {};
    
        if(userId){

            if(filterSupplier){
                searchParams.supplier = filterSupplier;
            }
            if(source){
                searchParams.source = source;
            }

            client.authenticate()
                .then(()=>{
                    return client.service('products').find({
                        query: {
                            ...searchParams,
                            userId,
                            $skip: skip,
                            orRegex: { name: regexProductName },
                            $limit: PAGESIZE,
                            $sort: tableSorting
                        }
                    });
                })
                .then((res) => {
                    setCheckboxChecked([]);
                    setPagination(paginationCount(res.total, currentPage));
                    fetchProducts(res.data);
                })
                .then(()=>{
                    return client.service('margins').find({
                        query: {
                            userId: adminUserId,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    fetchMargins(res.data);
                })
                .then(()=>{
                    return client.service('dynamicPrice').find({
                        query: {
                            userId: adminUserId,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    fetchDynamicPrice(res.data);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

    const productStatus = (_id, status) => {
        client.authenticate()
            .then(()=>{
                return client.service('products').patch(_id, {status: !status});
            })
            .then((res) => {
                props.updateProducts(res);
                notificationOpen(true, 'success', 'Product ' + res.name + ' Edited Successfully!');
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    // const listItemTop = (_id) => {
    //   client.authenticate()
    //   .then(()=>{
    //     return client.service('products').patch(_id, {dateToFirst: new Date()})
    //   })
    //   .then((res) => {
    //     props.updateProducts(res)
    //     props.moveProducts(res)
    //     notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
    //   })
    //   .catch((err)=>{
    //     if(err.name === "NotAuthenticated"){
    //       notificationOpen(true, 'danger', "Please Sign-in to continue!")
    //     }else{
    //       notificationOpen(true, 'danger', err.message)
    //     }
    //   })
    // }
  
    const PaginationRender = () => {
        if(pagination.last_page > 0){
            let pageArr = [];
            for(let i = 1; i <= pagination.last_page; i++){
                pageArr.push(i);
            }
            return (
                <Pagination
                    className='pagination justify-content-center mb-0'
                    listClassName='justify-content-center mb-0'
                >
                    <PaginationItem className={(pagination.current_page > 1?'':'disabled')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                                fetchData(user_Id, props.fetchProducts, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1, props.fetchMargins, props.fetchDynamicPrice, props.userId, regexProductName, supplier, source);
                            }} 
                            tabIndex='-1'
                        >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {pageArr.map((i) => {
                        return (
                            <PaginationItem key={i} className={(pagination.current_page === i? 'active': '')}>
                                <PaginationLink
                                    onClick={() => {
                                        setPagination(paginationCount(pagination.total, i)); 
                                        fetchData(user_Id, props.fetchProducts, notificationOpen, (i-1)*PAGESIZE, i, props.fetchMargins, props.fetchDynamicPrice, props.userId, regexProductName, supplier, source);
                                    }} 
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem className={((pagination.current_page+1) > pagination.last_page?'disabled':'')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                                fetchData(user_Id, props.fetchProducts, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1, props.fetchMargins, props.fetchDynamicPrice, props.userId, regexProductName, supplier, source);
                            }} 
                        >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            );
        }else{
            return null;
        }
    };

    const duplicateProduct = () => {
    
        let cloneData = cloneDeep(dataEdit);
        delete cloneData._id;
        delete cloneData.__v;
        delete cloneData.updatedAt;
        delete cloneData.updatedBy;
        delete cloneData.userId;
        delete cloneData.createdAt;
        delete cloneData.createdBy;
        delete cloneData.productImg;
        cloneData.timelines.map(x => {
            delete x._id;
            return x;
        });

        if(cloneData.items){
            if(cloneData.items.length > 0){
                cloneData.items.map(x => {
                    delete x._id;
                    x.amountBetween.map(x1 => {
                        delete x1._id;
                        return x1;
                    });
                    x.variations.map(x1 => {
                        delete x1._id;
                        return x1;
                    });
                    return x;
                });
            }
        }

        cloneData.userId = user_Id;
        cloneData.name = cloneData.name + '-duplicate';
    
        client.authenticate()
            .then(()=>{
                return client.service('products').create(cloneData);
            })
            .then((res) => {
                props.pushProducts(res);
                notificationOpen(true, 'success', 'Product ' + res.name + ' Added Successfully!');
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };
  
    const handleCheckboxChange = (id) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        if(cloneCheckbox.includes(id)){
            cloneCheckbox = cloneCheckbox.filter(item => item !== id);
        }else{
            cloneCheckbox.push(id);
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const handleCheckAll = (check) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        cloneCheckbox = [];
        if(check){
            props.productsData.map(x => {
                cloneCheckbox.push(x._id);
            });
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const assignFirstMargin = () => {
        if(checkboxChecked.length > 0){
            toggleModalAssignMargin();
        }else{
            notificationOpen(true, 'warning', 'No Product is selected!');
        }
    };

    const deleteProduct = () => {
        client.authenticate()
            .then(()=>{
                return client.service('products').remove(dataEdit._id);
            })
            .then((res) => {
                props.removeProducts(res);
                notificationOpen(true, 'success', 'Product ' + res.name + ' Delete Successfully!');
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            {/* Page content */}
            <Container fluid>
                {/* Dark table */}
                <Row className='pt-4'>
                    <Col md='12'>
                        <div className='d-flex align-items-center'>
                            <p className='m-0 font-weight-bold'>
                                {user.firstName + ' ' + user.lastName}{' '}
                            </p>
                            <p className='m-0 font-weight-bold'>
                                ({user.email})
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className='pb-3 d-flex align-items-center'>
                    <Col md='6'>
                        <Input
                            placeholder='Search Product Name...'
                            className='w-auto d-inline-block mr-2 mb-2'
                            type='text'
                            bsSize='sm'
                            size={29}
                            value={regexProductName}
                            onChange={(e) => setRegexProductName(e.target.value)}
                        />
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle caret color='default' className='text-uppercase'>
                                {
                                    (source?.length > 20 && source?.indexOf(' ') <= 0)?
                                        productGroups?.find(x => x._id === source)?.groupName
                                        :
                                        source || 'Data Type'
                                }
                            </DropdownToggle>
                            <DropdownMenu className='w-100'>
                                <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                    {
                                        productGroups.map((x, i) => {
                                            return (
                                                <DropdownItem 
                                                    key={i}
                                                    className='text-capitalize' 
                                                    value={x._id} 
                                                    onClick={() => {
                                                        setSource(x._id);
                                                    }}
                                                >
                                                    {x.groupName}
                                                </DropdownItem>
                                            );
                                        })
                                    }
                                    {
                                        (env.sources).map((x, i) => {
                                            return (
                                                <DropdownItem 
                                                    key={i}
                                                    className='text-uppercase' 
                                                    value={x.value} 
                                                    onClick={() => {
                                                        setSource(x.value);
                                                    }}
                                                >
                                                    {x.display}
                                                </DropdownItem>
                                            );
                                        })
                                    }
                                    <DropdownItem 
                                        className='text-capitalize text-white bg-danger' 
                                        value={''} 
                                        onClick={() => {
                                            setSource('');
                                        }}
                                    >
                                        Clear
                                    </DropdownItem>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle caret color='default' className='text-uppercase'>
                                {supplier || 'Supplier'}
                            </DropdownToggle>
                            <DropdownMenu className='w-100'>
                                <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                    <DropdownItem 
                                        className='text-capitalize text-white bg-danger' 
                                        value={''} 
                                        onClick={() => {
                                            setSupplier('');
                                        }}
                                    >
                                        Clear
                                    </DropdownItem>
                                    {
                                        env.suppliers.map((x, i) => {
                                            return (
                                                <DropdownItem 
                                                    key={i}
                                                    className='text-uppercase' 
                                                    value={x} 
                                                    onClick={() => {
                                                        setSupplier(x);
                                                    }}
                                                >
                                                    {x}
                                                </DropdownItem>
                                            );
                                        })
                                    }
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                    <Col md='6' className='text-right'>
                        <Button
                            color='warning'
                            onClick={assignFirstMargin}
                        >
                            <span className='btn-inner--text'>Assign First Margin</span>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <div className='col'>
                        <Card className='shadow'>
                            <CardHeader className='bg-transparent border-0'>
                                <Media>
                                    <Media body>
                                        <h3 className='mb-0'>Product tables</h3>
                                    </Media>
                                </Media>
                            </CardHeader>
                            <div className='table-responsive mac-scrollbar'>
                                <Table className='align-items-center table-flush' style={{minHeight: 200}}>
                                    <thead>
                                        <tr>
                                            <th scope='col'>
                                                <input
                                                    className=''
                                                    type='checkbox'
                                                    id='rememberMe'
                                                    // checked={values.rememberMe}
                                                    // value={values.rememberMe}
                                                    onChange={(e) => handleCheckAll(e.target.checked)}
                                                />
                                            </th>
                                            <th scope='col'>
                                                Product Name
                                                {
                                                    tableSorting.name === 1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('name', -1)}>
                              <i className='fa fa-arrow-down' />
                          </span>)
                                                }
                                                {
                                                    tableSorting.name === -1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('name', 1)}>
                              <i className='fa fa-arrow-up' />
                          </span>)
                                                }
                                            </th>
                                            <th scope='col'>
                                                Data Type
                                                {
                                                    tableSorting.source === 1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('source', -1)}>
                              <i className='fa fa-arrow-down' />
                          </span>)
                                                }
                                                {
                                                    tableSorting.source === -1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('source', 1)}>
                              <i className='fa fa-arrow-up' />
                          </span>)
                                                }
                                            </th>
                                            <th scope='col'>
                                                Supplier
                                                Data Type
                                                {
                                                    tableSorting.supplier === 1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('supplier', -1)}>
                              <i className='fa fa-arrow-down' />
                          </span>)
                                                }
                                                {
                                                    tableSorting.supplier === -1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('supplier', 1)}>
                              <i className='fa fa-arrow-up' />
                          </span>)
                                                }
                                            </th>
                                            <th scope='col'>User Margin</th>
                                            <th scope='col'>Super Admin Margin</th>
                                            <th scope='col'>Status</th>
                                            {/* <th scope="col">Status</th> */}
                                            <th scope='col' className='text-right'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.productsData.length > 0? (
                                            props.productsData.map((v, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th scope='row'>
                                                            {/* <Media className="align-items-center">
                                <a
                                  href="/#"
                                  className="avatar rounded-circle mr-3"
                                  onClick={(e) => e.preventDefault()}
                                >
                                   {v.productImg.length > 0? (
                                    <div
                                      className="img rounded-circle overflow-hidden bg-color d-inline-block"
                                      style={{ height: '100%', verticalAlign: 'middle', borderStyle: 'none' }}
                                    >
                                      <img
                                        alt="entreship sol"
                                        className="w-100"
                                        src={v.productImg[0].fileUrl}
                                      />
                                    </div>
                                  ):(
                                    <i className="ni ni-image"></i>
                                  )}
                                </a>
                                <Media>
                                </Media>
                              </Media> */}
                                                            <input
                                                                className=''
                                                                type='checkbox'
                                                                id={v._id}
                                                                checked={checkboxChecked.includes(v._id)}
                                                                value={checkboxChecked.includes(v._id)}
                                                                onChange={(e) => handleCheckboxChange(v._id)}
                                                            />
                                                        </th>
                                                        <td>
                                                            <span className='mb-0 text-sm'>
                                                                {v.name}
                                                            </span>
                                                        </td>
                                                        {/* <td>
                              {
                                v.source === 'csv'? (
                                  <>
                                    <span>-</span>
                                  </>
                                ): (
                                  <>
                                    <span>{new Intl.NumberFormat('en-SG', { style: 'currency', currency: 'SGD' }).format(v.netAmount / props.rateMYRtoSGD)}</span>
                                    <br/>
                                    <span>{new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v.netAmount))}</span>
                                  </>
                                )
                              }
                            </td> */}
                                                        <td>{
                                                            (v.source?.length > 20 && v.source?.indexOf(' ') <= 0)?
                                                                productGroups?.find(x => x._id === v.source)?.groupName
                                                                :
                                                                v.source? sourceName(v.source) : '-'
                                                        }</td>
                                                        <td className='text-uppercase'>{v.supplier? v.supplier : '-'}</td>
                                                        <td>{!isEmpty(v.marginSuperName)?v.marginSuperName.marginName:'-'}</td>
                                                        <td>{!isEmpty(v.marginName)?v.marginName.marginName:'-'}</td>
                                                        <td>
                                                            <span className={classNames('badge', {
                                                                'bg-success text-secondary': v.status,
                                                                'bg-danger': !v.status
                                                            })}>
                                                                {v.status?'Active': 'Inactive'}
                                                            </span>
                                                        </td>
                                                        {/* <td>{moment(v.createdAt).format("MMM Do YYYY")}</td>
                            <td>{moment(v.updatedAt).format("MMM Do YYYY")}</td> */}
                                                        <td className='text-right'>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    className='btn-icon-only text-light'
                                                                    href='#pablo'
                                                                    role='button'
                                                                    size='sm'
                                                                    color=''
                                                                    onClick={(e) => e.preventDefault()}
                                                                >
                                                                    <i className='fas fa-ellipsis-v' />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='dropdown-menu-arrow' right>
                                                                    {/* <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalEdit()}}
                                  >
                                    Edit
                                  </DropdownItem> */}
                                                                    <DropdownItem
                                                                        className='bg-secondary'
                                                                        onClick={() => {setDataEdit(v); toggleModalMarginEdit();}}
                                                                    >
                                                                        Edit First Margin
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalDetailEdit()}}
                                  >
                                    Edit Detail
                                  </DropdownItem> */}
                                                                    <DropdownItem
                                                                        onClick={() => {
                                                                            setDataEdit(v);
                                                                            toggleModalConfirm();
                                                                            setModalEvent('duplicate');
                                                                        }}
                                                                    >
                                                                        Duplicate
                                                                    </DropdownItem>
                                                                    <DropdownItem
                                                                        className='text-danger'
                                                                        onClick={() => {
                                                                            setDataEdit(v);
                                                                            toggleModalConfirm();
                                                                            setModalEvent('delete');
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem
                                    onClick={() => listItemTop(v._id)}
                                  >
                                    List Top
                                  </DropdownItem> */}
                                                                    {/* <DropdownItem
                                    onClick={() => 
                                      {
                                        setStatusOnChange({id: v._id, status: v.status});
                                        toggleModalConfirm()
                                        setModalEvent('disable')
                                      }
                                    }
                                  >
                                    {v.status?"Disabled":"Enable"}
                                  </DropdownItem> */}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ): (
                                            <tr>
                                                <td>
                                                    <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                </td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            <CardFooter className='py-4'>
                                <nav aria-label='...'>
                                    <PaginationRender />
                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
            <ProductDescModalEdit 
                dataEdit={dataEdit}
                modalVisible={modalVisibleDetailEdit} 
                toggleModal={toggleModalDetailEdit}
                notificationOpen={notificationOpen} 
                updateProducts={props.updateProducts}
                rateMYRtoSGD={props.rateMYRtoSGD}
                user_Id={user_Id}
            />
            <ModalConfirmation 
                modalVisible={modalConfirm} 
                toggleModal={toggleModalConfirm} 
                modalCallback={modalCallback} 
                title={getConfirmTitle(modalEvent)}
                content={getConfirmContent(modalEvent, statusOnChange.status)}
            />
            <MarginsModalEdit 
                dataEdit={dataEdit}
                modalVisible={modalVisibleMarginEdit} 
                toggleModal={toggleModalMarginEdit}
                notificationOpen={notificationOpen} 
                updateProducts={props.updateProducts}
                marginsData={props.marginsData}
            />
            <AssignFirstMargin 
                checkboxChecked={checkboxChecked}
                modalVisible={modalVisibleAssignMargin} 
                toggleModal={toggleModalAssignMargin}
                notificationOpen={notificationOpen}
                updateProducts={props.updateProducts}
                marginsData={props.marginsData}
                productsData={props.productsData}
            />
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 
const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    productsData: state.products.data,
    companyInfo: state.company.data,
    marginsData: state.margins.data,
    dynamicPriceData: state.dynamicPrice.data,
    usersData: state.users.data,
    rateMYRtoSGD: state?.company?.data?.rateMYRtoSGD?state.company.data.rateMYRtoSGD:4.13,
    currency: state.role.details.user?state.role.details.user.currency?state.role.details.user.currency:'myr':'myr',
});

const mapDispatchToProps = {
    fetchProducts: fetchProducts,
    pushProducts: pushProducts,
    updateProducts: updateProducts,
    moveProducts: moveProducts,
    removeProducts: removeProducts,
    fetchMargins: fetchMargins,
    fetchDynamicPrice: fetchDynamicPrice
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProducts);
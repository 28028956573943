import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { pushProducts } from '../redux/actions/productsActions';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import Breadcrumb from '../components/Common/Breadcrumb.js';
import { useModal } from '../zustand/modalStore.js';

import client from '../feathers.js';
// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody,
    Container,
} from 'reactstrap';
import Notifications from '../components/Extra/Notifications.js';
import Dropdown from '../components/Common/Dropdown.js';
import CustomFieldSelector from '../components/Products/ProductsByComponents/CustomFieldSelector.js';

const validationSchema = Yup.object().shape({
    siteLimit: Yup.number().required('Site limit is required'),
    price: Yup.number().required('Price is required'),
    name: Yup.string().required('Name is required'),
});
const PackageCreate = (props) => {
    const { userId, role } = props;
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ existingPackageId, setExistingPackageId ] = useState();
    const [ existingPackage, setExistingPackage ] = useState();
    
    const { search } = useLocation();

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();

    useEffect(() => {
        const params = new URLSearchParams(search);
        setExistingPackageId(params.get('id'));
    }, [props.userId]);

    useEffect(() => {
        if(userId && existingPackageId) {
            client.authenticate()
                .then((auth)=>{
                    return client.service('packages').get(existingPackageId);
                })
                .then((res)=>{
                    const sanitizedValue = cloneDeep(res);
                    sanitizedValue.moduleRestrictions.costBreakdown = (sanitizedValue.moduleRestrictions.costBreakdown) ? '1' : '';
                    sanitizedValue.moduleRestrictions.calculation.enabled = (sanitizedValue.moduleRestrictions.calculation.enabled) ? '1' : '';
                    sanitizedValue.moduleRestrictions.templateLink = (sanitizedValue.moduleRestrictions.templateLink) ? '1' : '';
                    sanitizedValue.moduleRestrictions.customWhatsapp = (sanitizedValue.moduleRestrictions.customWhatsapp) ? '1' : '';
                    setExistingPackage(sanitizedValue);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, [existingPackageId]);

    const notificationOpen = (bool, color, message) => {
        setIsOpen(bool);
        setColor(color);
        setMessage(message);
    };

    const onSubmit = async(value) => {
        const sanitizedValue = cloneDeep(value);
        sanitizedValue.moduleRestrictions.costBreakdown = Boolean(sanitizedValue.moduleRestrictions.costBreakdown);
        sanitizedValue.moduleRestrictions.calculation.enabled = Boolean(sanitizedValue.moduleRestrictions.calculation.enabled);
        sanitizedValue.moduleRestrictions.templateLink = Boolean(sanitizedValue.moduleRestrictions.templateLink);
        sanitizedValue.moduleRestrictions.customWhatsapp = Boolean(sanitizedValue.moduleRestrictions.customWhatsapp);

        if (existingPackageId) {
            client.authenticate()
                .then(()=>{
                    return client.service('packages').update(value._id, sanitizedValue);
                })
                .then((res) => {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Package Updated!');
                    modalActions.setContent('Package has been successfully updated.');
                    modalActions.setCallback(undefined);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('Unauthorized!');
                        modalActions.setContent('Please Sign-in to continue!');
                        modalActions.setCallback(() => props.history.push('/auth/login'));
                    }else{
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('Something went wrong!');
                        modalActions.setContent(err.message);
                        modalActions.setCallback(undefined);
                    }
                });
        } else {
            client.authenticate()
                .then(()=>{
                    return client.service('packages').create(sanitizedValue);
                })
                .then((res) => {
                    pushProducts(res);
                    setExistingPackageId(res._id);
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Package Created!');
                    modalActions.setContent('Package has been successfully created.');
                    modalActions.setCallback(undefined);
                    props.history.push(`/${props.role}/packages-create?id=${res._id}`);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('Unauthorized!');
                        modalActions.setContent('Please Sign-in to continue!');
                        modalActions.setCallback(() => props.history.push('/auth/login'));
                    }else{
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('Something went wrong!');
                        modalActions.setContent(err.message);
                        modalActions.setCallback(undefined);
                    }
                });
        }
    };

    const renderField = (location, title) => {
        return (
            <Row className='m-0 mb-2 d-flex align-items-center justify-content-between'>
                <small className='m-0'>{title}</small>
                <Field 
                    className='form-control form-control-sm form-control-alternative w-50'
                    placeholder='0'
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    name={location}
                />
            </Row>
        );
    };

    const renderRadio = (location) => {
        return (
            <Row className='m-0 d-flex align-items-center'>
                <label className='d-flex align-items-center mb-0 mr-3'>
                    <Field style={{marginRight: '3px'}} type='radio' name={location} value={''} default/>
                    <small className='mr-1 mb-0 w-10'>Enabled</small>
                </label>
                <label className='d-flex align-items-center mb-0 mr-3'>
                    <Field style={{marginRight: '3px'}} type='radio' name={location} value={'1'} />
                    <small className='mr-1 mb-0 w-10'>Restricted</small>
                </label>
            </Row>
        );
    };


    return (
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}

            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='mr-4 mb-0'>{(existingPackage) ? 'Edit Package' : 'Create Package'}</h2>
                </Row>
                <Breadcrumb
                    items={[
                        {title: 'Packages', link: `/${role}/packages`},
                        {title: (existingPackage) ? 'Edit Package' : 'Create Package'}
                    ]}
                />
                <div className='mt-2'>
                    <Formik
                        initialValues={{
                            _id: (existingPackage) ? existingPackage._id : undefined,
                            userId,
                            name: (existingPackage) ? existingPackage.name : undefined,
                            duration: (existingPackage) ? existingPackage.duration : undefined,
                            description: (existingPackage) ? existingPackage.description : undefined,
                            siteLimit: (existingPackage) ? existingPackage.siteLimit : 0,
                            price: (existingPackage) ? existingPackage.price : 0,
                            productRestrictions: (existingPackage) ? existingPackage.productRestrictions : {
                                api: 0,
                                csv: 0,
                                booklet: 0,
                                looseSheet: 0,
                                poster: 0,
                                total: 0,
                            },
                            componentRestrictions: (existingPackage) ? existingPackage.componentRestrictions : {
                                bindingType: 0,
                                material: 0,
                                printingSize: 0,
                                printingCost: 0,
                                quantity: 0,
                                productionTimeline: 0,
                                artwork: 0,
                                additionalComponent: 0,
                                dynamicPrice: 0,
                                profitMargin: 0,
                                fileStorage: 0,
                            },
                            moduleRestrictions: (existingPackage) ? existingPackage.moduleRestrictions : {
                                costBreakdown: '',
                                calculation: { 
                                    enabled: '',
                                    limit: 0,
                                },
                                templateLink: '',
                                customWhatsapp: '',
                            },
                        }}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                    >
                        {formik => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            } = formik;

                            return (
                                <Form role='form'
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}
                                >
                                    <div className='modal-body p-0'>
                                        <Row>
                                            <Col md='12'>
                                                <div className='px-3 mb-4 rounded'>
                                                    <Row className='p-3 p-2 px-4 border rounded-top bg-white'>
                                                        <h4 className='m-0'>Package Information</h4>
                                                    </Row>
                                                    <Row className='bg-secondary border-bottom border-left border-right p-2 py-4 rounded-bottom'>
                                                        <Col md='2'>
                                                            <h5 className='mb-1'>Site Limit</h5>
                                                            <Field 
                                                                className='form-control form-control-sm form-control-alternative'
                                                                placeholder='0'
                                                                type='number'
                                                                onWheel={(e) => e.target.blur()}
                                                                name={'siteLimit'}
                                                            />
                                                            {errors.siteLimit && touched.siteLimit ? (
                                                                <small className='text-danger xs'>{errors.siteLimit}</small>
                                                            ) : null}
                                                        </Col>
                                                        <Col md='2'>
                                                            <h5 className='mb-1'>Price</h5>
                                                            <Field 
                                                                className='form-control form-control-sm form-control-alternative'
                                                                placeholder='0'
                                                                type='number'
                                                                onWheel={(e) => e.target.blur()}
                                                                name={'price'}
                                                            />
                                                            {errors.price && touched.price ? (
                                                                <small className='text-danger xs'>{errors.price}</small>
                                                            ) : null}
                                                        </Col>
                                                        <Col md='4'>
                                                            <h5 className='mb-1'>Package Name</h5>
                                                            <Field 
                                                                className='form-control form-control-sm form-control-alternative'
                                                                placeholder='Name'
                                                                type='text'
                                                                name={'name'}
                                                            />
                                                            {errors.name && touched.name ? (
                                                                <small className='text-danger xs'>{errors.name}</small>
                                                            ) : null}
                                                        </Col>
                                                        <Col md='4'>
                                                            <h5 className='mb-1'>Description</h5>

                                                            <Field 
                                                                className='form-control form-control-sm form-control-alternative'
                                                                placeholder='Description'
                                                                type='textarea'
                                                                name={'description'}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Card className='mb-4'>
                                                    <CardBody className='rounded'>
                                                        <Col md='7'>
                                                            <Row className='p-2 px-4 mb-1'>
                                                                <h4>Package Settings</h4>
                                                            </Row>

                                                            <Row className='p-2'>
                                                                <Col md='6'>
                                                                    <h5 className='m-0 text-underline'>Package Duration</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row className='p-2 mb-3'>
                                                                <Col md='4'>
                                                                    <Row className='m-0 mb-2 d-flex align-items-center justify-content-between'>
                                                                        <small className='m-0'>Interval</small>
                                                                        <Dropdown
                                                                            name={'duration'}
                                                                            values={[
                                                                                {
                                                                                    display: 'Monthly',
                                                                                    value: 1,
                                                                                },
                                                                                {
                                                                                    display: 'Quarterly',
                                                                                    value: 3,
                                                                                },
                                                                                {
                                                                                    display: 'Annually',
                                                                                    value: 12,
                                                                                },
                                                                            ]}
                                                                            width={'auto'}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    </Row>
                                                                </Col>
                                                            </Row>

                                                            <Row className='p-2'>
                                                                <Col md='6'>
                                                                    <h5 className='m-0 text-underline'>Products Restrictions</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row className='p-2 mb-3'>
                                                                <Col md='6'>
                                                                    {renderField('productRestrictions.api', 'Group (API)')}
                                                                    {renderField('productRestrictions.csv', 'CSV')}
                                                                    {renderField('productRestrictions.booklet', 'SC-Booklet')}

                                                                </Col>
                                                                <Col md='6'>
                                                                    {renderField('productRestrictions.looseSheet', 'SC-Sheet')}
                                                                    {renderField('productRestrictions.poster', 'SC-Area')}
                                                                    {renderField('productRestrictions.total', 'Maximum Product')}
                                                                </Col>
                                                            </Row>
                                                            
                                                            <Row className='p-2'>
                                                                <Col md='6'>
                                                                    <h5 className='m-0 text-underline'>Component Restrictions</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row className='p-2 mb-3'>
                                                                <Col md='6'>
                                                                    {renderField('componentRestrictions.bindingType', 'Binding Type')}
                                                                    {renderField('componentRestrictions.material', 'Material')}
                                                                    {renderField('componentRestrictions.printingSize', 'Printing Size')}
                                                                    {renderField('componentRestrictions.printingCost', 'Printing Cost')}
                                                                    {renderField('componentRestrictions.quantity', 'Quantity')}
                                                                    {renderField('componentRestrictions.productionTimeline', 'Production Timeline')}
                                                                </Col>
                                                                <Col md='6'>
                                                                    {renderField('componentRestrictions.artwork', 'Artwork Service')}
                                                                    {renderField('componentRestrictions.additionalComponent', 'Additional Component')}
                                                                    {renderField('componentRestrictions.dynamicPrice', 'Dynamic Pricing')}
                                                                    {renderField('componentRestrictions.profitMargin', 'Profit Margin')}
                                                                    {renderField('componentRestrictions.fileStorage', 'File Storage')}
                                                                </Col>
                                                            </Row>
                                                        
                                                            <Row className='p-2'>
                                                                <Col md='6'>
                                                                    <h5 className='m-0 text-underline'>Module Restrictions</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row className='p-2 d-flex align-items-center'>
                                                                <Col md='12'>
                                                                    <Row className='m-0 mb-2 d-flex align-items-center'>
                                                                        <small className='m-0' style={{width: '150px'}}>Cost Breakdown</small>
                                                                        {renderRadio('moduleRestrictions.costBreakdown')}
                                                                    </Row>
                                                                    <Row className='m-0 mb-2 d-flex align-items-center'>
                                                                        <small className='m-0' style={{width: '150px'}}>Calculation Limit</small>
                                                                        {renderRadio('moduleRestrictions.calculation.enabled')}
                                                                        <Field 
                                                                            className='form-control form-control-sm form-control-alternative w-25 ml-3'
                                                                            placeholder='0'
                                                                            type='number'
                                                                            onWheel={(e) => e.target.blur()}
                                                                            name={'moduleRestrictions.calculation.limit'}
                                                                            disabled={!values.moduleRestrictions.calculation.enabled}
                                                                        />
                                                                    </Row>
                                                                    <Row className='m-0 mb-2 d-flex align-items-center'>
                                                                        <small className='m-0' style={{width: '150px'}}>Template Link</small>
                                                                        {renderRadio('moduleRestrictions.templateLink')}
                                                                    </Row>
                                                                    <Row className='m-0 mb-2 d-flex align-items-center'>
                                                                        <small className='m-0' style={{width: '150px'}}>Custom WhatsApp</small>
                                                                        {renderRadio('moduleRestrictions.customWhatsapp')}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                           
                                        </Row>
                                    </div>
                                    <div className='modal-footer'>
                                        <Button
                                            color='secondary'
                                            data-dismiss='modal'
                                            type='button'
                                            onClick={() => {
                                                props.history.push(`/${props.role}/components`);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color='primary' 
                                            type='submit'
                                        >
                                            Save as Active
                                        </Button>
                                    </div>

                                    <CustomFieldSelector
                                        userId={userId}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        notificationOpen={notificationOpen}
                                    />
                                </Form>
                            );
                        }}
                        
                    </Formik>
                </div>
                <Notifications 
                    isOpen={isOpen} 
                    handleOpen={notificationOpen} 
                    message={message} 
                    color={color}
                />
                <ModalWithCallback
                    isOpen={modalIsOpen}
                    toggle={() => modalActions.toggle()}
                    title={modalTitle}
                    content={modalContent}
                    callback={modalCallback}
                />
            </Container>
        </>
    );
};


const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(PackageCreate);
import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/common/delete.svg';
import { ReactComponent as DoneStepIcon } from '../../../assets/img/icons/common/doneStep.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { FieldArray } from 'formik';
import ComponentNumberInput from '../ComponentNumberInput.js';
import ComponentTextInput from '../ComponentTextInput.js';
import DynamicPriceInput from '../DynamicPriceInput.js';
import Dropdown from '../../Common/Dropdown.js';

// reactstrap components
import {
    Button,
    Row,
    Col,
} from 'reactstrap';
import DefaultSize from '../DefaultSize';
import Frame from './Frame.js';
import Eyelet from './Eyelet.js';
import CustomEyelet from './CustomEyelet.js';

const PosterOptions = ({
    values,
    setFieldValue,
    dynamicPriceData,
    componentTypeName
}) => {
    
    return (  
        <>
            {
                values.calcType === 'Perimeter'?
                    <>
                        <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                            {values.defaultSize ? (
                                <DoneStepIcon className='mr-4'/>
                            ) : (
                                <div className='mr-4 d-flex align-items-center justify-content-center'
                                    style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                    1
                                </div>
                            )}
                            <DefaultSize
                                width={270}
                                background={'white'}
                            />                                      
                        </Row>
                        <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                            <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                {values.data.options.length > 0 ? (
                                    <DoneStepIcon/>
                                ) : (
                                    <div className='d-flex align-items-center justify-content-center'
                                        style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                        2
                                    </div>
                                )}
                            </Col>
                            <Col md='11'>
                                <Row className='m-0 d-flex align-items-center'>
                                    <Col md='6' className='p-0'>
                                        <small className='mr-5 mb-1'>Costing Optons* </small>
                                    </Col>
                                </Row>
                                <Row className='m-0 d-flex align-items-center'>
                                    <Col md='3' className='p-0'>
                                        <small className='mr-5 mb-1'>Type</small>
                                        <Dropdown
                                            name='type'
                                            setFieldValue={setFieldValue}
                                            values={[
                                                {
                                                    value: 'frame',
                                                    display: 'Frame/Pole/Bunting'
                                                },
                                                {
                                                    value: 'eyelet',
                                                    display: 'Eyelet'
                                                },
                                            ]}
                                            width={'auto'}
                                        />
                                    </Col>
                                </Row>
                                <Row className='m-0 d-flex align-items-center'>
                                    <Col md='3' className='p-0'>
                                        <small className='mr-5 mb-1'>Options</small>
                                    </Col>
                                    {
                                        values.type === 'frame' &&
                                        <>
                                            <Col md='2' className='p-0 d-flex align-items-center justify-content-between'>
                                                <small>Top</small>
                                                <small>Bottom</small>
                                                <small>Left</small>
                                                <small>Right</small>
                                            </Col>
                                            <Col md='2' className='pr-0 d-flex align-items-center justify-content-between'>
                                                <small>Min({values.defaultSize || 'cm'})</small>
                                                <small>Max({values.defaultSize || 'cm'})</small>
                                            </Col>
                                            <Col md='1' className='pr-0'>
                                                <small>Base Cost</small>
                                            </Col>
                                            <Col md='1' className='pr-0'>
                                                <small>Min Amt</small>
                                            </Col>
                                            <Col md='1' className='pr-0'>
                                                <small>Price/{values.defaultSize || 'cm'}</small>
                                            </Col>
                                        </>
                                    }
                                </Row>
                                <FieldArray
                                    className='mb-3'
                                    name='data.options'
                                    render={({ push, remove, move }) => (
                                        <>
                                            <Col className='mb-3 p-0'>
                                                <DragDropContext onDragEnd={(result) => {
                                                    if (!result.destination) {
                                                        return;
                                                    }
                        
                                                    move(result.source.index, result.destination.index);
                                                }}>

                                                    <Droppable droppableId='options'>
                                                        {(provided) => (
                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                {values.data.options.map((option, index) => (
                                                                    <Draggable key={index} draggableId={`option-${index}`} index={index}>
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                {
                                                                                    values?.type === 'eyelet' &&
                                                                                        <Eyelet values={values} index={index} remove={remove} setFieldValue={setFieldValue} />
                                                                                }
                                                                                {
                                                                                    values?.type === 'frame' &&
                                                                                        <Frame values={values} index={index} remove={remove} setFieldValue={setFieldValue} />
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </Col>

                                            {
                                                values?.type &&
                                                    <Row className='d-flex align-items-start mb-1' style={{ paddingLeft: 15}}>
                                                        <a 
                                                            href='/' 
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                push({
                                                                    label: undefined,
                                                                    basePrice: 0,
                                                                    pricePer: 0,
                                                                    wastage: 0,
                                                                });
                                                            }}      
                                                        >
                                                            <span>
                                                                <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                                Add more options
                                                            </span>
                                                        </a>
                                                    </Row>
                                            }
                                        </>
                                    )}
                                />

                                {
                                    values?.type === 'eyelet' && 
                                        <Col md='4' className='p-0 mb-2'>
                                            <small className='mb-2'>Custom Eyelets</small>
                                            <div className='d-flex align-items-center'>
                                                <small className='mx-2'>No</small>
                                                <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                                    <input 
                                                        type='checkbox' 
                                                        checked={values.data.customEyelets}
                                                        onClick={() => setFieldValue('data.customEyelets', !values.data.customEyelets)}
                                                    />
                                                    <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                                                </label>
                                                <small className='mx-2'>Yes</small>
                                            </div>
                                        </Col>
                                }

                                {
                                    (values.data.customEyelets && values?.type === 'eyelet') &&
                                    <CustomEyelet values={values} />
                                }
                            
                            </Col>
                        </Row>
                    </>
                    : <>
                        <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                            <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                {values.data.options.length > 0 ? (
                                    <DoneStepIcon/>
                                ) : (
                                    <div className='d-flex align-items-center justify-content-center'
                                        style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                        2
                                    </div>
                                )}
                            </Col>
                            <Col md='11'>
                                <Row className='m-0 d-flex align-items-center'>
                                    <Col md='6' className='p-0'>
                                        <small className='mr-5 mb-1'>{componentTypeName} Optons* </small>
                                    </Col>
                                </Row>
                                <FieldArray
                                    className='mb-3'
                                    name='data.options'
                                    render={({ push, remove, move }) => (
                                        <>
                                            <Col className='mb-3 p-0'>
                                                <DragDropContext onDragEnd={(result) => {
                                                    if (!result.destination) {
                                                        return;
                                                    }
                        
                                                    move(result.source.index, result.destination.index);
                                                }}>

                                                    <Droppable droppableId='options'>
                                                        {(provided) => (
                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                {values.data.options.map((option, index) => (
                                                                    <Draggable key={index} draggableId={`option-${index}`} index={index}>
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <Col md='12' className='p-0'>
                                                                                    {values?.applicableSource?.includes('poster') ? (
                                                                                        <Row key={index} className='m-0 mb-1 align-items-center justify-content-start'>
                                                                                            <Col className='pr-0'>
                                                                                                <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                                                    <ComponentTextInput
                                                                                                        hasDrag
                                                                                                        placeholder='Label'
                                                                                                        location={`data.options[${index}].label`}
                                                                                                    />
                                                                                                </Row>
                                                                                            </Col>

                                                                                            <Col className='p-0'>
                                                                                                <ComponentNumberInput
                                                                                                    type={'number'}
                                                                                                    className='mr-2'
                                                                                                    title={'Base Price'}
                                                                                                    location={`data.options[${index}].basePrice`}
                                                                                                />
                                                                                            </Col>

                                                                                            <Col className='p-0'>
                                                                                                <ComponentNumberInput
                                                                                                    type={'number'}
                                                                                                    className='mr-2'
                                                                                                    title={'Print Cost'}
                                                                                                    location={`data.options[${index}].pricePer`}
                                                                                                    chipText={values.calcType}
                                                                                                />
                                                                                            </Col>

                                                                                            {values.calcType === 'Sqft' && (
                                                                                                <Col className='p-0'>
                                                                                                    <ComponentNumberInput
                                                                                                        type={'number'}
                                                                                                        className='mr-2'
                                                                                                        title={'Wastage'}
                                                                                                        location={`data.options[${index}].wastage`}
                                                                                                        chipText={values.calcType}
                                                                                                    />
                                                                                                </Col>
                                                                                            )}
                                                                                                                

                                                                                            <Col md='1'>
                                                                                                <Button 
                                                                                                    key={'asd'}
                                                                                                    color='danger'
                                                                                                    className='btn-round'
                                                                                                    onClick={() => remove(index)}
                                                                                                >
                                                                                                    <DeleteIcon 
                                                                                                        width={14}
                                                                                                        height={14}
                                                                                                    />
                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    ) : (
                                                                                        <Row key={index} className='m-0 mb-1 align-items-center justify-content-start'>
                                                                                            <Col md='4' className='pr-0'>
                                                                                                <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                                                    <ComponentTextInput
                                                                                                        hasDrag
                                                                                                        placeholder='Label'
                                                                                                        location={`data.options[${index}].label`}
                                                                                                    />
                                                                                                </Row>
                                                                                            </Col>

                                                                                            <Col md='3' className='p-0'>
                                                                                                <ComponentNumberInput
                                                                                                    type={'number'}
                                                                                                    className='mr-2'
                                                                                                    title={'Base Price'}
                                                                                                    location={`data.options[${index}].basePrice`}
                                                                                                />
                                                                                            </Col>

                                                                                            <Col md='3' className='p-0'>
                                                                                                <ComponentNumberInput
                                                                                                    type={'number'}
                                                                                                    className='mr-2'
                                                                                                    title={'Print Cost'}
                                                                                                    location={`data.options[${index}].pricePer`}
                                                                                                    chipText={values.calcType}
                                                                                                />
                                                                                            </Col>

                                                                                            <Col md='1'>
                                                                                                <Button 
                                                                                                    key={index}
                                                                                                    color='danger'
                                                                                                    className='btn-round'
                                                                                                    onClick={() => remove(index)}
                                                                                                >
                                                                                                    <DeleteIcon 
                                                                                                        width={14}
                                                                                                        height={14}
                                                                                                    />
                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    )}
                                                                                </Col>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </Col>

                                            <Row className='d-flex align-items-start mb-1' style={{ paddingLeft: 15}}>
                                                <a 
                                                    href='/' 
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        push({
                                                            label: undefined,
                                                            basePrice: 0,
                                                            pricePer: 0,
                                                            wastage: 0,
                                                        });
                                                    }}      
                                                >
                                                    <span>
                                                        <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                        Add more options
                                                    </span>
                                                </a>
                                            </Row>
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                    </>
            }
            <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                <Col md='0.5' className='p-0 d-flex justify-content-center'>
                    {(values.dynamicPriceId) ? (
                        <DoneStepIcon className='mr-4'/>
                    ) : (
                        <div className='d-flex align-items-center justify-content-center'
                            style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                            3
                        </div>
                    )}
                </Col>
                <Col md='11'>
                    <Row className='m-0 d-flex align-items-center'>
                        <small className='mr-1 mb-1'>Dynamic Price (optional) </small>
                    </Row>
                    <DynamicPriceInput
                        setFieldValue={setFieldValue}
                        dynamicPriceData={dynamicPriceData.filter((x) => x.type !== 'dynamicPrice' && x.type !== 'quantity')}
                        location={'dynamicPriceId'}
                    />
                </Col>
            </Row>
        </>
    );
};
 
export default PosterOptions;
import React from 'react';
// reactstrap components
import {
    Row,
    Col
} from 'reactstrap';

const performChecks = (values) => {
    if(!values.avaiSources.booklet) values.avaiSources.booklet = false;
};
const AvaiSources = ({
    values,
    handleChange
}) => {
    performChecks(values);
    return (  
        <>
            <hr className='my-4' />
            <label
                className='form-control-label mb-0'
                htmlFor='currency'
            >
                Sources
            </label>
            <Row>
                
                <Col sm='3' md='3'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Poster
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.poster'
                                checked={values.avaiSources.poster}
                                onChange={handleChange}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
                <Col sm='3' md='3'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Loose
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.looseSheet'
                                checked={values.avaiSources.looseSheet}
                                onChange={handleChange}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
                <Col sm='3' md='3'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Booklet
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.booklet'
                                checked={values.avaiSources.booklet}
                                onChange={handleChange}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
            </Row>
        </>
    );
};
 
export default AvaiSources;
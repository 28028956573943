import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Field } from 'formik';
import { getQuantities } from '../../../../utils/productComponentUtil.js';

// reactstrap components
import {
    Row,
    Col,
} from 'reactstrap';

import client from '../../../../feathers.js';
import axios from 'axios';
import Dropdown from '../../../Common/Dropdown.js';
import { convertUnit } from '../../../../utils/converter.js';

var typingTimer = 0;

const BookletPreviewByComponent = (props) => {
    const { role, values, userInfo, userId, productDynamicPriceId, productSuperDynamicPriceId, marginId, marginSuperId, setFieldValue, packageDetails } = props;
    const [ allComponents, setAllComponents ] = useState();
    const [ bindingTypes, setBindingTypes ] = useState();
    const [ printingSize, setPrintingSize ] = useState();
    const [ selectedContentMaterial, setSelectedContentMaterial ] = useState();
    const [ allMaterials, setAllMaterials ] = useState();
    
    const [ coverMaterials, setCoverMaterials ] = useState();
    const [ coverPrintingCost, setCoverPrintingCost ] = useState();
    const [ coverOptions, setCoverOptions ] = useState();

    const [ contentMaterials, setContentMaterials ] = useState();
    const [ contentPrintingCost, setContentPrintingCost ] = useState();
    const [ contentOptions, setContentOptions ] = useState();

    const [ quantity, setQuantity ] = useState();
    const [ additionalData, setAdditionalData ] = useState([]);

    const [ pricing, setPricing ] = useState(0);
    const [ loadingPreview, setLoadingPreview ] = useState(false);

    const [ enabledCostBreakdown, setEnabledCostBreakdown ] = useState(true);

    const timeoutRef = useRef(null);

    useEffect(() => {
        if (userInfo.package) {
            if (packageDetails?.moduleRestrictions?.costBreakdown) {
                setEnabledCostBreakdown(false);
            }
        }
    }, [userInfo, packageDetails]);

    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then(()=>{
                    setCoverOptions([]);
                    setContentOptions([]);
                    const componentIds = [];
                    values.bookletData.bindingTypeComponents.map((x) => {
                        componentIds.push(x.componentId);
                    });

                    values.bookletData.coverComponents.materialComponents.map((x) => {
                        componentIds.push(x.componentId);
                        x.nestedComponents.map((x) => {
                            componentIds.push(x.componentId);
                        });
                    });

                    values.bookletData.coverComponents.printCostComponents.map((x) => {
                        componentIds.push(x.componentId);
                    });

                    values.bookletData.coverComponents.additionalComponents.map((x) => {
                        componentIds.push(x.componentId);
                    });

                    values.bookletData.contentComponents.materialComponents.map((x) => {
                        componentIds.push(x.componentId);
                        x.nestedComponents.map((x) => {
                            componentIds.push(x.componentId);
                        });
                    });

                    values.bookletData.contentComponents.printCostComponents.map((x) => {
                        componentIds.push(x.componentId);
                    });

                    values.bookletData.contentComponents.additionalComponents.map((x) => {
                        componentIds.push(x.componentId);
                    });                    

                    values.bookletData.printSizeComponents.map((x) => {
                        componentIds.push(x.componentId);
                    });

                    values.bookletData.quantityComponents.map((x) => {
                        componentIds.push(x.componentId);
                    });

                    values.bookletData.additionalComponents.map((x) => {
                        componentIds.push(x.componentId);
                    });

                    const distinctComponents = [...new Set(componentIds)];

                    return client.service('components').find({
                        query: {
                            _id: { $in: distinctComponents.map((x) => x) },
                            $limit: 1000,
                        }
                    });
                })
                .then((res)=>{
                    const materialsData = res.data.filter((x) => x.componentTypeName === 'Material');
                    setAllMaterials(materialsData);

                    setBindingTypes(res.data.filter((x) => x.componentTypeName === 'Binding Type'));
                    setPrintingSize(res.data.find((x) => x.componentTypeName === 'Printing Size'));

                    // Cover
                    const coverMaterialsData = [];
                    values.bookletData.coverComponents.materialComponents.map((x) => {
                        const cover = res.data.find((m) => m._id === x.componentId);
                        coverMaterialsData.push(cover);
                    });
                    setCoverMaterials(coverMaterialsData);

                    const coverPrintCostData = [];
                    values.bookletData.coverComponents.printCostComponents.map((x) => {
                        const cover = res.data.find((pc) => pc._id === x.componentId);
                        coverPrintCostData.push(cover);
                    });
                    setCoverPrintingCost(coverPrintCostData);
                    
                    const coverOptionsData = [];
                    values.bookletData.coverComponents.additionalComponents.map((x) => {
                        const cover = res.data.find((o) => o._id === x.componentId);
                        coverOptionsData.push(cover);
                    });
                    setCoverOptions(coverOptionsData);


                    // Content
                    const contentData = [];
                    const c2c = values.bookletData.coverToContentPairing.find((x) => x.componentId === values.preview.coverMaterial);
            
                    if(c2c) {
                        c2c.content.map((x) => {
                            if (x.enabled) {
                                contentData.push(allMaterials.find((m) => m._id === x.componentId));
                            }
                        });
                    }
                    setContentMaterials(contentData);

                    const contentPrintCostData = [];
                    values.bookletData.contentComponents.printCostComponents.map((x) => {
                        const content = res.data.find((pc) => pc._id === x.componentId);
                        contentPrintCostData.push(content);
                    });
                    setContentPrintingCost(contentPrintCostData);

                    const contentOptionsData = [];
                    values.bookletData.contentComponents.additionalComponents.map((x) => {
                        const content = res.data.find((o) => o._id === x.componentId);
                        contentOptionsData.push(content);
                    });
                    setContentOptions(contentOptionsData);
                    setQuantity(res.data.find((x) => x.componentTypeName === 'Quantity'));

                    return {
                        res,
                        coverOptionsData,
                        contentOptionsData
                    };
                }).then(({ res, coverOptionsData, contentOptionsData }) => {

                    if (values.preview.coverMaterial) {
                        const selectedCoverMaterial = values.bookletData.coverComponents.materialComponents.find((x) => x.componentId === values.preview.coverMaterial);

                        let newCoverOptions = coverOptionsData;
                        if(selectedCoverMaterial.nestedComponents.length > 0) {
                            selectedCoverMaterial.nestedComponents.map((x) => {
                                newCoverOptions.push(res.data.find((o) => o._id === x.componentId));
                            });
                        }
                        setCoverOptions([...newCoverOptions]);
                    }
                    if (values.preview.contentMaterial) {
                        const selectedContentMaterial = values.bookletData.contentComponents.materialComponents.find((x) => x.componentId === values.preview.contentMaterial);

                        let newContentOptions = contentOptionsData;
                        if(selectedContentMaterial.nestedComponents.length > 0) {
                            selectedContentMaterial.nestedComponents.map((x) => {
                                newContentOptions.push(res.data.find((o) => o._id === x.componentId));
                            });
                        }
                        setContentOptions([...newContentOptions]);
                    }

                    setAllComponents(res.data);
                });
        }
    },[userId, values]);

    useEffect(() => {
        if (values.preview.contentMaterial) {
            const contentMaterial = allMaterials.find((x) => x._id === values.preview.contentMaterial);
            setSelectedContentMaterial(contentMaterial);
        }
    },[values.preview.contentMaterial]);
    
    const updatePrice = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            axios({
                method: 'post',
                url: `${client.io.io.uri}changeProductSpecs`,
                data: {
                    ...values,
                    productData: {
                        productId: values._id,
                        ...values
                    },
                    type: 'component',
                    preview: {
                        ...values.preview,
                        productionTimelineComponentId: values.productionComponents[0]?.componentId ?? '',
                        artworkId: values.artworkComponents[0]?.componentId ?? '',
                        productDynamicPriceId,
                        productSuperDynamicPriceId,
                        marginId,
                        marginSuperId
                    }
                },
                config: { headers: {'Content-Type': 'application/json' }}
            })
                .then((res) => {
                    setPricing(res.data);
                    setLoadingPreview(false);

                    if(res.data.additionalData) {
                        setAdditionalData([...res.data.additionalData]);
                    }
                })
                .catch((err) => {
                    setLoadingPreview(false);
                    console.log(err);
                });
        }, 500);
    };

    // on change preview
    useEffect(() => {
        if (values.preview.bindingType) {
            setLoadingPreview(true);
            updatePrice();
            // Cleanup the previous timeout on re-render
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }
    }, [values]);


    const renderLoader = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            if (enabledCostBreakdown) {
                return value;
            } else {
                return <span className='xs font-italic'> Upgrade to view</span>;
            }
        }
    };

    const renderFinalPrice = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            return value;
        }
    };

    const getPP = (min, max) => {
        const incrementedPP = [];
        for (let i = min; i <= max; i+=4) {
            incrementedPP.push(i);
        }
  
        return incrementedPP;
    };

    return ( 
        <>
            <div className='preview-container bg-secondary rounded py-2 mb-3'>
                {loadingPreview && (
                    <div
                        className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 100 }}
                    >
                        <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                )}
                <Row className='m-0 px-4 pt-2 w-100'>
                    <h3 className='m-0 color-primary'>Price Calculator</h3>
                </Row>
                <Row className='m-0 px-4 py-2 w-100'>
                    <small>Preview the cost of your product here.</small>
                </Row>
                {(bindingTypes) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5 className='d-flex align-items-center'>Binding Type - {renderLoader(pricing.pricingBreakdown?.bindingTypePrice)}</h5>
                        <Dropdown
                            name={'preview.bindingType'}
                            setFieldValue={setFieldValue}
                            values={bindingTypes?.map((x) => {
                                return {
                                    value: x._id,
                                    display: x.name
                                };
                            })}
                        />
                    </Row>
                )}

                {(printingSize) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5 className='d-flex align-items-center'>Size - {renderLoader(pricing.pricingBreakdown?.sizePrice)}</h5>
                        <Dropdown
                            name={'preview.printingSize'}
                            setFieldValue={setFieldValue}
                            values={
                                [...printingSize.data.printingSizeDetails.sizes.map((x) => {
                                    return {
                                        value: `${printingSize._id}~~${x._id}`,
                                        display: x.label
                                    };
                                }),
                                (printingSize.data.printingSizeDetails.hasCustomSize) ? {
                                    value: `${printingSize._id}~~Custom`,
                                    display: 'Custom',
                                } : undefined
                                ]
                            }
                        />
                    </Row>
                )}

                {((values.preview.printingSize) && values.preview.printingSize.includes('Custom')) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <Col className='m-0 p-0 pr-1' md='6'>
                            <small>Width ({printingSize?.defaultSize ?? 'mm'})</small>
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                name={'preview.size.customWidth'}
                            />
                            {values.preview.size.customWidth < printingSize.data.printingSizeDetails.minWidth && (
                                <small className='text-danger xs'>Must be higher than {printingSize.data.printingSizeDetails.minWidth}</small>
                            )}
                            {values.preview.size.customWidth > selectedContentMaterial.data.printingArea.maxWidth && (
                                <small className='text-danger xs'>Must be lower than {convertUnit(selectedContentMaterial.data.printingArea.maxWidth, selectedContentMaterial.defaultSize, printingSize.defaultSize)}</small>
                            )}
                        </Col>
                        <Col className='m-0 p-0 pl-1' md='6'>
                            <small>Height ({printingSize?.defaultSize ?? 'mm'})</small>
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                name={'preview.size.customHeight'}
                            />
                            {values.preview.size.customHeight < printingSize.data.printingSizeDetails.minHeight && (
                                <small className='text-danger xs'>Must be higher than {printingSize.data.printingSizeDetails.minHeight}</small>
                            )}
                            {values.preview.size.customHeight > selectedContentMaterial.data.printingArea.maxHeight && (
                                <small className='text-danger xs'>Must be lower than {convertUnit(selectedContentMaterial.data.printingArea.maxHeight, selectedContentMaterial.defaultSize, printingSize.defaultSize)}</small>
                            )}
                        </Col>
                    </Row>
                )}

                {(coverMaterials) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5 className='d-flex align-items-center'>Cover Material - {renderLoader(pricing.pricingBreakdown?.coverMaterialPrice)}</h5>
                        <Dropdown
                            name={'preview.coverMaterial'}
                            setFieldValue={setFieldValue}
                            values={coverMaterials?.map((x) => {
                                return {
                                    value: x._id,
                                    display: x.name
                                };
                            })}
                        />
                    </Row>
                )}

                {coverPrintingCost && coverPrintingCost.map((x, i) => {
                    const price = pricing.pricingBreakdown?.coverPrintCostPrices?.find((x) => x.index === i)?.price;
                    return (
                        <Row key={`${x.name}-${i}`} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>
                                {x.name} - {renderLoader(price)}
                            </h5>
                            <Dropdown
                                name={`preview.coverPrintCosts[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data.printingCost?.map((o) => {
                                    return {
                                        value: `${x._id}~~${o._id}`,
                                        display: o.label
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(coverOptions) && coverOptions.map((x, i) => {
                    const price = pricing.pricingBreakdown?.coverOptionPrices?.find((x) => x.index === i)?.price;
                    return (
                        <Row key={`${x.name}-${i}`} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>
                                {x.name} - {renderLoader(price)}
                            </h5>
                            <Dropdown
                                name={`preview.coverOptions[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data.options?.map((o) => {
                                    return {
                                        value: `${x._id}~~${o._id}`,
                                        display: o.label
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(contentMaterials) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5 className='d-flex align-items-center'>Content Material - {renderLoader(pricing.pricingBreakdown?.contentMaterialPrice)}</h5>
                        <Dropdown
                            name={'preview.contentMaterial'}
                            setFieldValue={setFieldValue}
                            values={contentMaterials?.map((x) => {
                                return {
                                    value: x._id,
                                    display: x.name
                                };
                            })}
                        />
                    </Row>
                )}

                {contentPrintingCost && contentPrintingCost.map((x, i) => {
                    const price = pricing.pricingBreakdown?.contentPrintCostPrices?.find((x) => x.index === i)?.price;
                    return (
                        <Row key={`${x.name}-${i}`} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>
                                {x.name} - {renderLoader(price)}
                            </h5>
                            <Dropdown
                                name={`preview.contentPrintCosts[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data.printingCost?.map((o) => {
                                    return {
                                        value: `${x._id}~~${o._id}`,
                                        display: o.label
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(contentOptions) && contentOptions.map((x, i) => {
                    const price = pricing.pricingBreakdown?.contentOptionPrices?.find((x) => x.index === i)?.price;
                    return (
                        <Row key={`${x.name}-${i}`} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>
                                {x.name} - {renderLoader(price)}
                            </h5>
                            <Dropdown
                                name={`preview.contentOptions[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data.options?.map((o) => {
                                    return {
                                        value: `${x._id}~~${o._id}`,
                                        display: o.label
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(selectedContentMaterial) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5 className='d-flex align-items-center'>Content: Printed Pages (Excluding Cover)</h5>
                        <Dropdown
                            name={'preview.pp'}
                            setFieldValue={setFieldValue}
                            values={getPP(selectedContentMaterial.metadata.minPP, selectedContentMaterial.metadata.maxPP).map((x) => {
                                return {
                                    value: x,
                                    display: x
                                };
                            })}
                        />
                    </Row>
                )}
    
                {(quantity) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5>Quantity</h5>
                        {(quantity.data.quantity.isCustom) ? (
                            <Dropdown
                                name={'preview.quantity'}
                                setFieldValue={setFieldValue}
                                values={getQuantities(quantity.data.quantity.isCustom, quantity)}
                            />
                        ) : (
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                max={quantity.data.quantity.isInfinite ? 999999 : quantity.data.quantity.max}
                                min={quantity.data.quantity.min}
                                name={'preview.quantity'}
                            />
                        )}
                    </Row>
                )}
    
                {additionalData.map((x, i) => {
                    return (
                        <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>{x.title} - {renderLoader(values.additionalDataPrices[i])}</h5>
                            <Dropdown
                                name={`additionalDataPrices[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data?.map((d) => {
                                    return {
                                        value: d.price,
                                        display: d.title
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(values.fileStorageComponents.length > 0) && (
                    <>
                        <Row className='m-0 px-4 py-2 w-100'>
                            <h5>File Upload</h5>
                            <Col md='12' className='p-3 m-0 rounded additional-info-container'>
                                <small>Upload files here</small>
                            </Col>
                        </Row>
                        
                    </>
                )}

                {(values.customFields.length > 0) && (
                    <>
                        {values.customFields.map((x, i) => {
                            return (
                                <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                                    <h5 className='d-flex align-items-center'>{x}</h5>
                                    <Field 
                                        className='form-control form-control-sm form-control-alternative'
                                        type='text'
                                        name={`customFieldsTemp[${i}]`}
                                    />
                                </Row>
                            );
                        })}
                    </>
                )}
    
                <Row className='mt-4 m-0 px-4 py-0 w-100'>
                    <h5 className='m-0 d-flex align-items-center'>Order Total</h5>
                </Row>
                <Row className='m-0 px-4 py-0 w-100'>
                    <h1 className='d-flex align-items-center color-primary'>{renderFinalPrice((Number(pricing.price ?? 0) + values.additionalDataPrices?.reduce((a, b) => Number(a) + Number(b), 0)).toFixed(2))}</h1>
                </Row>
            </div>

            <Row className='m-0 px-4 py-3 w-100 rounded additional-info-container'>
                <h3 className='d-flex align-items-center'>Additional Info</h3>
                {!!pricing.metadata?.coverPcsUP && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Cover UP: {renderLoader(pricing.metadata?.coverPcsUP)}</small>
                    </Row>
                )}
                {!!pricing.metadata?.contentPcsUP && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Content UP: {renderLoader(pricing.metadata?.contentPcsUP)}</small>
                    </Row>
                )}
                {!!pricing.metadata?.coverNoSheets && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Total Cover Sheets: {renderLoader(pricing.metadata?.coverNoSheets)}</small>
                    </Row>
                )}
                {!!pricing.metadata?.contentNoSheets && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Total Content Sheets: {renderLoader(pricing.metadata?.contentNoSheets)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.marginPercentage || !!pricing?.metadata?.superMarginPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Margin Percentage: +{renderLoader((((role === 'superadmin') ? pricing.metadata?.superMarginPercentage - 1 : pricing.metadata?.marginPercentage - 1) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.discountPercentage || !!pricing?.metadata?.superDiscountPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Dynamic Price Discount: -{renderLoader((((role === 'superadmin') ? 1 - pricing?.metadata?.superDiscountPercentage: 1 - pricing.metadata?.discountPercentage) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}
            </Row>
        </>
    );
};

const mapStateToProps = state => ({
    userInfo: state.role.details.user,
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(BookletPreviewByComponent);
